import React, { useState,useEffect } from "react";
// import { Link } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import { useNavigate } from "react-router-dom";

import { 
  Input,
  Box,
  Flex, 
  Text,  
  TableContainer, 
  Table,
  Thead, 
  Tr, 
  Td, 
  Th, 
  Tbody, 
  Tooltip ,
  Img,
  useMediaQuery,
  Center

} from "@chakra-ui/react";

import { useAuth, useExams } from "../../hooks/index.js";

import { formatDate } from "../../helpers/index.js";

import { Pagination } from "../Pagination/index.js";

import ptBR from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

import { CalendarIcon } from "@chakra-ui/icons";

import Search from "../../images/search.svg";

import ErrorStatus from "../../images/errorStatus.svg";
import PaletaExames from "../paletaExames.jsx";
import FilterDesktop from "./Filter/FilterDesktop/index.jsx";
import FilterMobile from "./Filter/FilterMobile/index.jsx";


// const breakpoints = {
//   sm: "320px",
//   md: "768px",
//   lg: "960px",
//   xl: "1200px",
//   "2xl": "1536px",
// };

const GridAttendance = ({ 
  itemsPage,
  uuidSelected

}) => {
  const { user } = useAuth();

  const { 
    newAttendanceRange
   } = useExams();
  
  const [isMobile] = useMediaQuery("(max-width: 830px)") 

  const navigate = useNavigate();

  // const [isMobile] = useMediaQuery("(max-width: 768px)") 

  registerLocale("ptBR", ptBR);

  const [openDateStart, setOpenDateStart] = useState(false); 
  const [openDateEnd, setOpenDateEnd] = useState(false);
  const [showExams, setShowExams] = useState(null);

  // const [uuidFilter, setUuidFilter] = useState("");

  const [widthScreen] = useState(0);
  const [search, setSearch] = useState("");

  const itemsPerPage = itemsPage;
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [abnormal , setAbnormal ] = useState("0");

  var today = new Date();
  var lastSixMonths = new Date(today);
  const [ endDate, setEndDate ] = useState(new Date());

  var daysQuery = 60;

  if(user.role_id === 3) {
    daysQuery = 1825;

  }
  
  const [startDate, setStartDate] = useState(
    today.setDate(lastSixMonths.getDate() - daysQuery),
  );

  async function getFilteredExamsList() {

    try {

      if (!uuidSelected) {
        if(user.hash === null){
          return;
        }
        uuidSelected = user.hash;
      } 
      
      setOpenDateStart(false);
      setOpenDateEnd(false);

      const params = {
        pageNo: currentPage ? currentPage + 1 : 1,
        pageSize: itemsPerPage,
        Order: "DESC",
        NomePaciente: search ? search : "",
        filterAbnormal: abnormal,
      };

      if (user.role_id === 1) {
        Object.assign(params, { uuidHopistal: uuidSelected });
      }

      let CONVERTED_START_DATE = new Date(startDate);
      let CONVERTED_END_DATE = new Date(endDate);


      CONVERTED_START_DATE = CONVERTED_START_DATE.toISOString().slice(0, 10);
      CONVERTED_END_DATE = CONVERTED_END_DATE.toISOString().slice(0, 10);

      const response = await newAttendanceRange(
        uuidSelected,
        CONVERTED_START_DATE,
        CONVERTED_END_DATE,
        params,
      );
      setShowExams(response?.Atendimentos?.[0]?.lista_atendimento);
      setPageCount(response?.Atendimentos?.[0]?.total_pages);
    } catch (error) {
      console.error(error);
    }
  }

  // function Datepicker(date, setDate) {
  //   if (setDate === "Start") {
  //     setStartDate(date);
  //     setOpenDateStart(openDateStart === true ? false : true);
  //   }
  //   if (setDate === "End") {
  //     setEndDate(date);
  //     setOpenDateEnd(openDateEnd === true ? false : true);
  //   }
  // }

  const showDetails = async (numatendimento) => {
    if(!numatendimento) return;
    
    if(uuidSelected){
      navigate(`/resultados-exames/${uuidSelected}/${numatendimento}`);
    }
  }; 

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      getFilteredExamsList();
    }
  };

  useEffect(() => {
    // if (user?.role_id === 3 || user?.role_id === 4) {
      getFilteredExamsList();
    // }
  }, [currentPage]);

   useEffect(() => {
     setCurrentPage(0);
    
   }, [uuidSelected]);


  // useEffect(() => {
  //   console.log("isMobile", isMobile)

  // }, []);

  return (

    <>
          <Box
            m="0px 0"
            p="10px 20px"
            border="1px solid #CED4DA"
            borderRadius="10px"
            // backgroundColor="var(--cinza-bg)"
            backgroundColor="#fff"
            width="100%"
          >

            {/* Cabeçalho */}
            <>
              {
                isMobile ? (
                  <Center >
                    <Text 
                          color="#F3953F" 
                          fontSize={isMobile ? "1.5em" : "2em" } as="strong"
                          fontWeight={800}
                          
                    >
                      Resultado de Exames      
                    </Text>
                </Center>
                ) : (
  
                  <Text 
                        color="#F3953F" 
                        fontSize={isMobile ? "1.5em" : "2em" } as="strong"
                        fontWeight={800}
                         
                  >
                    Resultado de Exames      
                  </Text>

                )

              }

              <Flex gap="20px">

                {

                  isMobile ? (

                    <FilterMobile
                      openDateEnd={openDateEnd} 
                      openDateStart={openDateStart}
                      setOpenDateEnd={setOpenDateEnd}
                      setOpenDateStart={setOpenDateStart}
                      startDate={startDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      endDate={endDate}
                      daysQuery={daysQuery}
                      setAbnormal={setAbnormal}
                      abnormal={abnormal}
                      getFilteredExamsList={getFilteredExamsList}
                      today={today}
                      lastSixMonths={lastSixMonths}          
                    />
                    
                  ) : (

                    <FilterDesktop 
                      openDateEnd={openDateEnd} 
                      openDateStart={openDateStart}
                      setOpenDateEnd={setOpenDateEnd}
                      setOpenDateStart={setOpenDateStart}
                      startDate={startDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      endDate={endDate}
                      daysQuery={daysQuery}
                      setAbnormal={setAbnormal}
                      abnormal={abnormal}
                      getFilteredExamsList={getFilteredExamsList} 
                      today={today}
                      lastSixMonths={lastSixMonths}
                    />
                  )


                }
              

              </Flex>


              {user?.role_id !== 5 && user?.role_id !== 3 && (
                <Flex gap="50px">
                  <Flex
                    w="200%"
                    p="0 12px"
                    border="1px solid #CED4DA"
                    color="#8F8F8F"
                    borderRadius="4px"
                    alignContent="center"
                    alignItems="center"
                    gap="10px"
                    mb="10px"
                  >
                    <img src={Search} alt="" />
                    <Text as="strong">Pesquisar:</Text>
                    <Input
                      padding="0"
                      border="none"
                      placeholder="Procure por nome do paciente"
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyDown={handleKeyDown}
                      name="name"
                    />
                  </Flex>
                </Flex>
              )}
            </>
            
            <Flex
              fontSize="13px"
              // overflowX="scroll"
              // scrollBehavior="none"
              flexDirection="column"
              // border="1px solid #CED4DA"
            >
              <TableContainer
                border="1px solid #CED4DA"
                borderRadius="6px"
                w="100%"
                // h="494px"
                // maxH="516px"
                maxW={`calc(100% - ${widthScreen})`}
                // overflowY="scroll"
              >
                {showExams?.length > 0 ? (
                  <Table
                    variant="simple"
                    border="var(--cinza-card) 1px solid"
                    borderRadius="50px"
                  >
                    <Thead>
                      <Tr borderRadius="8px">
                        {/* <Th borderRadius="8px" maxW="50px"></Th> */}
                        <Th maxW="200px">Nome do Paciente</Th>
                        <Th maxW="90px">
                          Número de<br />atendimento
                        </Th>
                        <Th maxW="150px">Procedência</Th>
                        <Th maxW="180px">
                          Médico <br /> Solicitante
                        </Th>
                        {/* <Th maxW="190px">Grupo/Unidade</Th> */}
                        <Th maxW="110px">Data Coleta</Th>
                        <Th maxW="100px">
                          Previsão de <br /> conclusão
                        </Th>
                        <Th maxW="80px">Status</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {showExams?.map((exam, index) => (
                        <Tr
                          onClick={() => showDetails(exam?.numatendimento)}
                          cursor="pointer"
                          key={exam.numatendimento}
                          _hover={{ backgroundColor: "#F3953F15" }}
                          backgroundColor={
                            exam.status === 3 ? "#FF000015" : "white"
                          }
                        >
                          {/* <Td maxW="50px" key="index">
                            <Checkbox />
                          </Td> */}
                          <Td
                            maxW="220px"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                          >
                            <Flex justifyContent="space-between">
                              <Tooltip
                                hasArrow
                                label={exam?.nomepaciente}
                                bg="gray.300"
                                color="black"
                              >
                                <Text as="strong">{exam?.nomepaciente}</Text>
                              </Tooltip>
                              <Img
                                h="25px"
                                src={exam?.status === 3 ? ErrorStatus : ""}
                              />
                            </Flex>
                          </Td>
                          <Td textAlign="center">{exam?.numatendimento}</Td>
                          <Td
                            maxW="120px"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                          >
                            <Tooltip
                              hasArrow
                              label={exam?.nomeprocedencia}
                              bg="gray.300"
                              color="black"
                            >
                              <Flex flexDirection="column">
                                <Text>{exam?.nomeprocedencia}</Text>
                              </Flex>
                            </Tooltip>
                          </Td>
                          <Td
                            maxW="180px"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                          >
                            <Tooltip
                              hasArrow
                              label={exam?.nomemedico}
                              bg="gray.300"
                              color="black"
                            >
                              <Flex flexDirection="column">
                                <Text>{exam?.nomemedico}</Text>
                                <Text>{exam?.CRMDoctor}</Text>
                              </Flex>
                            </Tooltip>
                          </Td>
                          {/* <Td
                            maxW="200px"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                          >
                            <Tooltip
                              hasArrow
                              label={exam?.nomeprocedencia}
                              bg="gray.300"
                              color="black"
                            >
                              <Text as="strong">{exam?.nomeprocedencia}</Text>
                            </Tooltip>
                          </Td> */}
                          <Td maxW="80px">{formatDate(exam?.dataentrada)}</Td>

                          <Td maxW="80px">{formatDate(exam?.dataresultado)}</Td>
                          <Td>
                            <Flex
                              w="102px"
                              border="1px solid var(--cinza-card)"
                              borderRadius="5px"
                              p="6px"
                              h="100%"
                            >
                              <Flex
                                h="10px"
                                w={exam.status}
                                fontSize="1px"
                                backgroundColor={exam.progressbackgroudcolor}
                                color={exam.progressfontcolor}
                              ></Flex>
                            </Flex>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                ) : (
                  <Text p="80px 20px">
                    Não existem dados com essa Data. <br /> Por favor selecione
                    uma data diferente.
                  </Text>
                )}
              </TableContainer>
            </Flex>
            {/* Button imprimir laudos */}
            <Flex p="20px 20px 0 0" justifyContent="space-between">
              <PaletaExames />
              <Pagination
                pageCount={pageCount}
                changePage={setCurrentPage}
                pageSelected={currentPage}
              />
            </Flex>

          </Box>

    </>

  );
};

export default GridAttendance;
