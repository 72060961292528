import React from "react";

import DatePicker, { registerLocale } from "react-datepicker";
import { formatDate } from "../../../../helpers/index.js";
import ptBR from "date-fns/locale/pt-BR";

import { 
  Text,
  Flex,
  RadioGroup,
  Radio,
  Button,
  Box
} from "@chakra-ui/react";

import { CalendarIcon } from "@chakra-ui/icons";


const FilterMobile = ({
    openDateStart, 
    openDateEnd, 
    setOpenDateEnd, 
    setOpenDateStart, 
    daysQuery, 
    setAbnormal, 
    abnormal, 
    getFilteredExamsList,
    today,
    lastSixMonths,
    setStartDate,
    setEndDate,
    startDate,
    endDate
}) => {
  // var today = new Date();
  // var lastSixMonths = new Date(today);

  // const [openDateStart, setOpenDateStart] = useState(false); 
  // const [openDateEnd, setOpenDateEnd] = useState(false);

  // const [endDate, setEndDate] = useState(new Date());

  // const [startDate, setStartDate] = useState(
  //   today.setDate(lastSixMonths.getDate() - daysQuery),
  // );

  function Datepicker(date, setDate) {
    if (setDate === "Start") {
      setStartDate(date);
      setOpenDateStart(openDateStart === true ? false : true);
    }
    if (setDate === "End") {
      setEndDate(date);
      setOpenDateEnd(openDateEnd === true ? false : true);
    }

  }

  return (
    <>
      
        <Flex flexDirection="column" w="100%" align="center">
            
          <Box w="70%" alignItems="center"justifyContent="center" textAlign="center" alignContent="center" mb="1">

            <Text as="strong" align="center">Data Início</Text>
            <Flex
              w="100%"
              p="5px 12px"
              border="1px solid #CED4DA"
              color="#8F8F8F"
              
              backgroundColor="white"
              borderRadius="4px"
              alignItems="center"
              mb="10px"
              
            >
              <DatePicker
                open={openDateStart}
                locale="ptBR"
                
                dateFormat="dd/MM/yyyy "
                selected={startDate}
                onChange={(date) => Datepicker(date, "Start")}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                maxDate={endDate}
                
              />
              <CalendarIcon
                color="orange"
                cursor="pointer"
                right="10px"
                
                onClick={() =>
                  setOpenDateStart(openDateStart === true ? false : true)
                }
              />
            </Flex>

          </Box>

          
          <Box w="70%" alignItems="center"justifyContent="center" textAlign="center">
            <Text as="strong">Data Fim</Text>
            <Flex
              backgroundColor="white"
              p="5px 12px"
              border="1px solid #CED4DA"
              color="#8F8F8F"
              borderRadius="4px"
              alignContent="center"
              alignItems="center"
              
              mb="10px"
            >
              <DatePicker
                open={openDateEnd}
                border="1px solid black"
                width="100%"
                locale="ptBR"
                dateFormat="dd/MM/yyyy "
                selected={endDate}
                onChange={(date) => Datepicker(date, "End")}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                cursor="pointer"
              />
              <CalendarIcon
                color="orange"
                cursor="pointer"
                onClick={() =>
                  setOpenDateEnd(openDateEnd === true ? false : true)
                }
              />
            </Flex>

          </Box>  
          <Text as="strong">Filtros</Text>
          <RadioGroup
            name="abnormal"
            onChange={setAbnormal} 
            value={abnormal} 
            defaultValue="0"
          >
              <Radio value="0" pr={8} >Todos</Radio> 
              <Radio value="1" defaultChecked >Alterado</Radio>

          </RadioGroup>
          <Flex flexDirection="column" 
     
            w="100%" 
         
            alignContent="end"
            alignSelf="end"
            marginBottom="2"
          >
            <Button
              onClick={ () => getFilteredExamsList()}
              border="1px solid #CED4DA"
              w="100%"
              h="100%"
              backgroundColor="var(--orange-senne)"
              _hover={{ opacity: 0.7 }}
              p="10px 10px"
              color="white"
            >
              Pesquisar
            </Button>
          </Flex>
      
        </Flex>
      
      
    </>
  );
};

export default FilterMobile;
