import styled from "styled-components";
import Navbar from "../components/Navbar";
import HomeStyle from "../styles/home";
import CardBenefits from "../components/CardBenefits";
import { Link } from "react-router-dom";
import {
  PrimaryButton,
  SecondaryButton,

} from "../components/Buttons";

import { Box, Flex, Img, Text } from "@chakra-ui/react";

import imagem01 from "../images/icon-home-01.svg";
import imagem02 from "../images/icon-home-02.svg";
import imagem03 from "../images/icon-home-03.svg";
import imagem04 from "../images/icon-home-04.svg";

function Home() {
  const CardsContent = [
    {
      image: imagem01,
      text: "+120 hospitais e 60 operadoras em SP e Campinas",
    },
    {
      image: imagem02,
      text: "Atendimento ágil, seguro e confiável",
    },
    {
      image: imagem03,
      text: "Logística Integrada, retiramos amostra em todo o Brasil",
    },
    {
      image: imagem04,
      text: "Assessoria médica e exames completos",
    },
  ];

  return (
    <>
      <HomeStyle />
      <div className="HomeApp">
        <Navbar text="Paragraph" title="Title" />
        <div className="container row align-items-center">
          <Cards className="col-md-6">
            {CardsContent.map((card, index) => (
              <div key={index}>
                <CardBenefits logo={card.image} text={card.text} key={index} />
              </div>
            ))}
          </Cards>

          <LoginSection className="col-md-6">
            <Img src="/images/portalSenne.svg" alt=" " />
            <Flex
              color="white"
              className="justify-content-center flex-column "
              width="100%"
            >
              <p>
                Centro de referência em coleta e análise de líquor com
                experiência e inovação para trazer o melhor resultado.{" "}
              </p>
              <Flex
                gap="15px"
                flexDirection="column"
                w="90%"
                mt="8px"
                justifyContent="start"
                alignItems="start"
              >
                <Link to="/login">
                  <PrimaryButton>Entrar</PrimaryButton>
                </Link>
                <Box w="100%" textAlign="center">
                  <Text>Novo por aqui? Crie sua nova conta agora!</Text>
                  <Link to="/cadastro">
                    <SecondaryButton>Cadastre-se</SecondaryButton>
                  </Link>
                </Box>
              </Flex>
              {/* <Flex               
                color="white"
                className="justify-content-center flex-column"
                width="90%">

                
                <Box w="100%" textAlign="center" >
                  <Text>Para acesso ao antigo portal, clique nos botões abaixo</Text>
                  <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                    <Link 
                      to="https://sistemas.senneliquor.com.br/LaboratorioWeb/indexLaboratorio.jsp"
                    >
                      <GrayButton>Resultado</GrayButton>
                    </Link>
                
                    <Link to="https://sistemas.senneliquor.com.br:8088/">
                      <GrayButton>Agendamento</GrayButton>
                    </Link>

                  </Grid>

                </Box>
              </Flex> */}
            </Flex>
          </LoginSection>
        </div>
      </div>
    </>
  );
}

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* padding: 22px; */
  /* padding: 0 20px 0 0; */
  p {
    width: 100%;
    font-size: 0.66rem;
    line-height: 0.8rem;
    font-family: Montserrat;
  }
  > div {
    display: flex;
    width: calc(45% - 68px);
    margin: 15px;
  }
  @media screen and (max-width: 820px) {
    padding: 0 40px 0 0;
  }
  @media screen and (max-width: 800px) {
    padding: 80px 0;
  }
  @media screen and (max-width: 600px) {
    flex-wrap: nowrap;
    overflow-x: auto;
    margin: 24px 0;
    > div {
      min-width: 200px;
      width: 100%;
      margin: 0 24px;
    }
  }
  @media screen and (max-width: 414px) {
    div {
      padding: 10px;
      font-size: 10px;
    }
  }
`;

const LoginSection = styled.div`
  padding: 0 15px 0 0;
  img {
    height: 138px;

    margin: 16px 0;
  }

  p {
    margin: 0 0 16px;
  }
  a {
    width: 100%;
  }
  @media (max-width: 800px) {
    padding: 0 15px;
  }
`;

export default Home;
