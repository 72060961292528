import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  Text,
  Input,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from "@chakra-ui/react";

import { SearchIcon } from "@chakra-ui/icons";

import { GreenButton, TableButton } from "../../components/Buttons/index";
import SideBar from "../../components/SideBar";
import { Pagination } from "../../components/Pagination";
import { FormatFulldate } from "../../components/Format";
import InternalHeader from "../../components/InternalHeader";


//import { BreadCrumbs } from "../../components/Breadcrumb2";


import { useUsers, useAuth } from "../../hooks";

import Add from "../../images/iconAdd.svg";


function Users() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { getUsers, getUsersByGroup, inactiveUser, approveUser } = useUsers();

  const [usersToShow, setUsersToShow] = useState();


  const [users, setUsers] = useState();

  const [loading, setLoading] = useState(false);

  const [uuidHopistal] = useState(false);

  const [ selectedUser, setSelectedUser ] = useState([]);


  // eslint-disable-next-line no-unused-vars
  const [aprovedDoctor, setAprovedDoctor] = useState("");

  

  const itemsPerPage = 5;
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [ validateSearch, setValidateSearch ] = useState(true);


  // function types(role_id_usuario, GroupName) {
    
  //   if (role_id_usuario === 1) return "MASTER SENNE";
  //   if (role_id_usuario === 2) return `USUARIO ${GroupName}`;
  //   if (role_id_usuario === 3) return "PACIENTE";
  //   if (role_id_usuario === 4) return "MÉDICO";
  //   if (role_id_usuario === 5) return "PACIENTE AMBULATORIAL";
  // }


  const [uuidUser, setUUIDUser] = useState();
  
  async function handleOnChangeHospital(uuidChanged) {
    setUUIDUser(uuidChanged);
  };

  function getDate() {
    if (user?.role_id === 1) {
      setUUIDUser(uuidHopistal);
    }
    if (user?.role_id === 3) {
      setUUIDUser(user?.hash);
    }
    if (user?.role_id === 4) {
      setUUIDUser(user?.uuid_doc);
    }
  }


  async function AproveUsers(id) {
    try {
      setLoading(true);

      const data = {
        id: id,
      };
      await approveUser(id, data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      getUsersList();
      getUserListGroup();
    }
  }

  async function inactive_user(id, status) {

    

    try {
      const data = { status: status };

      await inactiveUser(id, data);

      if (user?.role_id === 1) {
        getUsersList();
      }
      if (user?.role_id === 2) {
        getUserListGroup();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getUsersList() {

    // if ( users?.length < 5) {
    //   return;
    // }
    try {
      setLoading(true);
      const params = {
        page: currentPage ? currentPage + 1 : 1,
        per_page: itemsPerPage,
        name: users,
      };
      const response = await getUsers(params);
      setUsersToShow(response?.Users?.data);
      setPageCount(response?.Users?.last_page);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);

    }
  }

  async function getUserListGroup() {

    // if ( users?.length < 5) {
    //   return;
    // }

    try {
      setLoading(true);

      
      const params = {
        page: currentPage ? currentPage + 1 : 1,
        per_page: itemsPerPage,
        name: users,
      };

      const response = await getUsersByGroup(
        user?.hospitals[0]?.grupo_id,
        params,
      );  
      setPageCount(response?.Users?.last_page);
      setUsersToShow(response?.Users?.data);

    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  }

  function handleSearchButton() {

    // if(validateSearch || loading) {
    //   return;
    // }    
    try {
      if (user?.role_id === 1) {
        getUsersList();
      }
      if (user?.role_id === 2) {
        getUserListGroup();
      }
    } catch (error) {
      console.error(error);
    } 
  }

  function handleActionButton(userId, userName, userStatus) {
    setSelectedUser([userId, userName, userStatus]);
    onOpen();
  }

  const handleKeyDown = (e) => {

    if (e.key === 'Enter' && !loading) {

      handleSearchButton();
    }
  };


  const handleInativeUser = () => {

    inactive_user(selectedUser[0], selectedUser[2] === 1 ? 0 : 1);

    onClose();

  };

  useEffect(() => {
    

    // if (users?.length >= 5) {
    //   setValidateSearch(false);
    // }

    // if (users?.length < 5 ) {
    //   setValidateSearch(true);
    // }
    if (
      user?.cod_pf ||
      user?.hash ||
      user?.hospitals?.[0]?.uuid ||
      uuidHopistal ||
      user?.uuid_doc
    ) {
      getDate();
    }

    if (!loading) {
      handleSearchButton();

    }



    // handleOnChangeHospital();

    // if(!validateSearch) {
    //   handleSearchButton();
    // }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);



  // useEffect(() => {
  //   // if (users?.length >= 5) {
  //   //   setValidateSearch(false);
  //   // }

  //   // if (users?.length < 5 ) {
  //   //   setValidateSearch(true);
  //   // }

  //   console.log(users);

  // }, [users]);


  return (
    <Flex width="100%" maxHeight="100vh" backgroundColor="#F7F8FC">
      {/* {loading && <Loading />} */}
      <Flex width="220px" id="sidebar" className="sidebar">
        <SideBar />
      </Flex>

      <Box p="24px 88px 22px 24px" w="calc(100% - 200px)">
        
        <InternalHeader  setHospital={handleOnChangeHospital} setUUIDUser={setUUIDUser} uuidUser={uuidUser} />
        <Box
          mt="44px"
          p="15px 20px"
          border="1px solid #CED4DA"
          borderRadius="10px"
          backgroundColor="#fff"
        >

        <Text 
            color="#F3953F" 
            fontSize={{ base: "16px", md: "26px" }} as="strong"
            fontWeight={800}>
                Gerenciamento de Usuários
         </Text>
          <Flex justifyContent="space-between">
            <Flex
              w="520px"
              p="0 12px"
              border="1px solid #CED4DA"
              color="#8F8F8F"
              borderRadius="4px"
              alignContent="center"
              alignItems="center"
              gap="10px"
            >
              
              <Text as="strong">Pesquisar:</Text>
              <Input
                padding="0"
                paddingLeft="10px"
                maxWidth="100%"
                _focus={{
                  border: "none",
                }}
                onKeyDownCapture={(e) => {handleKeyDown(e)}}
                onChange={(e) => setUsers(e.target.value)}
                border="none"
                errorBorderColor="red.300"
                
                isInvalid={validateSearch}
                placeholder="Pesquise pelo usuário"
              />
              
              <Button 
                as="strong" 
                w="80px"
                onClick={handleSearchButton}
                isLoading={loading}
                spinnerPlacement='start'
                onChange={handleKeyDown} 
                isDisabled={validateSearch}
              >
                <SearchIcon />
              </Button>
            </Flex>

            <Flex alignContent="center" alignItems="center">
              <Link to="/users/add">
                <TableButton>
                  <img src={Add} alt="" />
                  Adicionar Usuário
                </TableButton>
              </Link>
            </Flex>
          </Flex>
          
          

          <Flex
            mt="20px"
            pt="20px"
            borderTop="1px solid var(--orange-senne)"
            fontSize="13px"
            lineHeight="14px"
          >
            <TableContainer
              w="100%"
              borderRadius="8px"
              border="var(--cinza-card) 1px solid"
              maxH="300px"
              overflowY="scroll"
            >
              <Table variant="simple">
                <Thead backgroundColor="var(--cinza-bg)">
                  <Tr>
     
                    <Th 
                      borderLeft="var(--cinza-card) 1px solid"
                      textAlign="center"
                      w="220px"
                      maxW="220px"
                    >
                      Nome do usuário
                    </Th>
                    <Th 
                      borderLeft="var(--cinza-card) 1px solid"
                      textAlign="center"
                      w="30px"

                    >
                      Tipo de usuário
                    </Th>

                    <Th 
                      borderLeft="var(--cinza-card) 1px solid"
                      textAlign="center"
                      w="200px"
                      
                    >Unidade</Th>
                    <Th 
                      borderLeft="var(--cinza-card) 1px solid"
                      textAlign="center"
                      w={200}
                    >Email</Th>
                    <Th 
                      borderLeft="var(--cinza-card) 1px solid"
                      textAlign="center"
                      w={150}
                    >Status</Th>
                    <Th 
                      borderLeft="var(--cinza-card) 1px solid"
                      textAlign="center"
                      w="100px"
                    >Log</Th>
                    <Th 
                      borderLeft="var(--cinza-card) 1px solid"
                      textAlign="center"
                      w="20px"
                    >Ações</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {usersToShow?.map((user, index) => (
                    <Tr
                      key={index}
                      _hover={{
                        backgroundColor: "var(--orange-hover)",
                        cursor: "pointer",
                      }}
                      // onClick={() => navigate(`/users/${user?.id}`)}
                    >
                      {/* <Td p="0 8px">
                        <Flex w="100%" justifyContent="center">
                          <Checkbox />
                        </Flex>
                      </Td> */}
                      <Td
                        onClick={() => navigate(`/users/${user?.id}`)}
                        p="0 25px"
                        maxW="20%"
                        width="10%"

       
                        wordBreak="break-all"
                        
                        borderLeft="1px solid var(--cinza-card)"
                      >
                        {user?.name.toUpperCase()}
                      </Td>
                      <Td
                        onClick={() => navigate(`/users/${user?.id}`)}
                  
                        maxW="20px"
                        textAlign="center"
                        borderLeft="1px solid var(--cinza-card)"
                      >
                        {user.admin === 1 ? 
                          "Adminstrador" : 
                            
                            user.role_id === 4 ? `Médico - ${user.crm}` : "Usuário"}
     
                      </Td>
                      <Td
                        overflowY
                        maxH="15px"
                        maxW="500px"
                        
                        p="0 8px"
                        borderLeft="1px solid var(--cinza-card)"
                      >
                  
                        
                          {
                            user?.hospitais?.map((hospital, index) => (
                              <Text 
                                backgroundColor={"#ededed"}
                                marginBottom={"5px"}
                                borderRadius={"50px"}
                                paddingLeft={"10px"}
                                key={index}>- {hospital?.name}
                              </Text>
                            ))
                          }
                        
                      </Td>
                      <Td
                        onClick={() => navigate(`/users/${user?.id}`)}
                        p="0 8px"
                        borderLeft="1px solid var(--cinza-card)"
                        textAlign="center"
                      >
                          {user?.email}
                      </Td>
                      <Td
                        onClick={() => navigate(`/users/${user?.id}`)}
                        p="0 8px"
                        borderLeft="1px solid var(--cinza-card)"
                        textAlign="center"
                      >
                        <Button
                          _hover={{ opacity: 0.7 }}
                          fontSize="12px"
                          h="30px"
                          key={user?.id}
                          borderRadius="20px"
                          alignItems  ="center"
                          backgroundColor={
                            user?.status === 0
                              ? "var(--black-50)"
                              : user?.status === 1
                              ? "var(--blue-senne)"
                              : user?.status === 2
                              ? "var(--orange-senne)"
                              : "var(--orange-senne-secundary)"
                          }
                          color="white"
                        >
                          {user?.status === 0
                            ? "Inativo"
                            : user?.status === 1
                            ? "Ativo"
                            : user?.status === 2
                            ? "Pendente de ativação"
                            : user?.status === 3
                            ? "Pendente de aprovação"
                            : user?.status === 4
                            ? "Pendente de validação celular"
                            : ""}
                        </Button>
                      </Td>
                      <Td
                        onClick={() => navigate(`/users/${user?.id}`)}
                        p="15px 8px"
                        borderLeft="1px solid var(--cinza-card)"
                        textAlign="center"
                      >
                        {user?.last_login
                          ? FormatFulldate(user?.last_login)
                          : "Nunca Logou"}
                      </Td>

                      <Td p="0 8px" borderLeft="1px solid var(--cinza-card)">
                        <Menu>
                          <MenuButton
                            style={{
                              backgroundColor: "transparent",
                              display: "flex",
                              margin: "0 auto",
                            }}
                            as={IconButton}
                            icon={
                              <GreenButton
                                style={{
                                  padding: "0 8px 8px",
                                  width: "40px",
                                }}
                              >
                                ...
                              </GreenButton>
                            }
                          />
                          <MenuList>
                            <Link
                              fontSize="12px"
                              to={`/users/${user?.id}` || navigate("/users")}
                            >
                              <MenuItem fontSize="12px">
                                Visualizar Perfil
                              </MenuItem>
                            </Link>
                            {(user?.status === 0 || user?.status === 1) && (
                              <Link
                                fontSize="12px"
                                to={""}
                                 onClick={() => handleActionButton(user?.id, user?.name, user?.status)}
                                  //() =>
                                //   inactive_user(
                                //     user?.id,
                                //     user?.status === 0 ? 1 : 0,
                                //   )
                                //}
                              >
                                <MenuItem fontSize="12px">
                                  {user?.status === 1
                                    ? "Inativar Usuário"
                                    : user?.status === 0
                                    ? "Ativar Usuário"
                                    : ""}
                                </MenuItem>
                              </Link>
                            )}

                            {user?.status === 3 && user?.role_id !== 4 && (
                              <Link
                                fontSize="12px"
                                to={""}
                                onClick={() => AproveUsers(user?.id)}
                                isLoading={loading}
                                
                              >
                                <MenuItem fontSize="12px">
                                  <Text>Aprovar Usuário</Text>
                                </MenuItem>
                              </Link>
                            )}
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
                  <>
                    {/* <Button onClick={onOpen}>Open Modal</Button> */}
                    <Modal
                      // initialFocusRef={initialRef}
                      // finalFocusRef={finalRef}
                      isOpen={isOpen}
                      onClose={onClose}
                    >
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>Confirmar {selectedUser[3] === 1 ? "Inativação" : "Ativação"}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={10} >

                            <Text marginBottom={3}> Confirma Ativação do Usuário </Text> 

                            <Flex >
                              <Text as="strong">  {selectedUser[1]}</Text>
                            </Flex>
                            
                        </ModalBody>

                        <ModalFooter >
                                           
                            <Flex flexDirection="row" gap={20}  >
                              <Button
                                onClick={onClose}
                                w={150}
                                backgroundColor={"#DC3545"}
                                color={"#fff"}
                                
                              >Não
                              </Button>
                              <Button
                                id="actionUser"
                                w={150}
                                onClick={handleInativeUser}
                                backgroundColor={"#B9BD5C"}
                                color={"#fff"}
                                
                              >Sim
                              </Button>
                            </Flex>
                          
         
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                  </>
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
          <Flex mt="18px" justifyContent="end">
            <Pagination
              pageCount={pageCount}
              changePage={setCurrentPage}
              pageSelected={currentPage}
            />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
}

export default Users;
