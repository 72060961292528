import styled from "styled-components";
import InputMask from "react-input-mask";
import { ViewIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Flex,
  Input as ChakraInput,
} from "@chakra-ui/react";

export default function TextInput({
  id = "id_do_input_text",
  autoFocus = false,
  InputPlaceholder = "placeholder text",
  maskInput,
  isError,
  labelDescription,
  mask,
  register,
  leftAddOn,
  rightAddOn,
  colorLabel,
  type,
  defaultValue,
  password,
  onChange,
  ...rest
}) {
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(defaultValue);
  const [passwordView, setPasswordView] = useState("password");

  function view() {
    passwordView === "password"
      ? setPasswordView("text")
      : setPasswordView("password");
  }

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <BlockInput>
        <FormControl isInvalid={isError?.message}>
          <FormLabel textColor={colorLabel} fontSize="14px" m="0">
            {labelDescription}
          </FormLabel>

          <InputGroup>
            {leftAddOn && <InputLeftAddon children={leftAddOn} />}

            {maskInput ? (
              <>
                <ChakraInput
                  {...rest}
                  {...register}
                  as={InputMask}
                  // value={value}
                  mask={maskInput}
                  maskChar={null}
                  placeholder={InputPlaceholder}
                  onChange={onChange}
                />
              </>
            ) : (
              <Flex alignItems="center" width="100%">
                <ChakraInput
                  {...rest}
                  {...register}
                  autoFocus={autoFocus}
                  id={id}
                  type={type === "password" ? passwordView : type}
                  placeholder={InputPlaceholder}
                  // value={value}
                  onChange={onChange}
                />

                {password ? (
                  <Flex paddingTop="0px" marginLeft="-40px" zIndex="999">
                    <ViewIcon onClick={() => view()} />
                  </Flex>
                ) : (
                  ""
                )}
              </Flex>
            )}

            {rightAddOn && <InputRightAddon children={rightAddOn} />}
          </InputGroup>
          {isError && <FormErrorMessage>{isError?.message}</FormErrorMessage>}
        </FormControl>
      </BlockInput>
    </>
  );
}

const BlockInput = styled.div`
  width: 100%;
  margin: 10px 0px;

  label {
    display: block;
    font-size: 16px;
    line-height: 15px;
    color: #343a40;
    font-weight: bold;
    width: 100%;
    /* margin: 8px 0; */
  }

  input {
    display: block;
    background: #ffffff;
    border: 1px solid #d2d3e5;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 5px;
    padding: 10px;
    width: 100%;
  }
`;
