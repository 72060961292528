import React, { useState, useEffect } from "react";
import  { registerLocale } from "react-datepicker";

import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Box,
  extendTheme,
  useDisclosure,

} from "@chakra-ui/react";
// import { useNavigate } from "react-router-dom";
import ptBR from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

import SideBar from "../../components/SideBar";

// import { BreadCrumbs } from "../../components/Breadcrumb2";
import InternalHeader from "../../components/InternalHeader";
// import PaletaExames from "../../components/paletaExames.jsx";

import GridAuthorization from "../../components/GridAuthorization";
import GridAppointment from "../../components/GridAppointment";

import GridAttendance from "../../components/GridAttendance";

// import Search from "../../images/search.svg";
// import ErrorStatus from "../../images/errorStatus.svg";
// import TextSelectHospital from "../../components/TextSelectHospital";


// import RadioAbnormal  from "../../components/RadioAbnormal";

import ModalNotification from "../../components/ModalNotification";

import { useAuth, useExams, useHospitals, useUsers } from "../../hooks";

const breakpoints = {
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

extendTheme({ breakpoints });

function Dashboard() {
  //const navigate = useNavigate();
  registerLocale("ptBR", ptBR);

  const { 
    listAppointment,
    listAuthorization
   } = useExams();
  

  const { user } = useAuth();
  const { getHospitalsList, getUserHospitalList, getHospitalNotification } = useHospitals();
  const { getUser } = useUsers();


  const [showAppointment, setShowAppointment] = useState(null);
  const [showAuthorization, setShowAuthorization] = useState(null);

  
  const [userLogged, setUserLogged] = useState();

  const {  isOpen, onClose, onOpen } = useDisclosure();


  //const [firstTime, setFirstTime] = useState(false);

  const [uuidUser, setUUIDUser] = useState();
  //const [search, setSearch] = useState("");

  const itemsPerPage = 3;
  //const [pageCount, setPageCount] = useState(0);
  const [pageCountAppointment, setPageCountAppointment] = useState(0);
  const [pageCountAuthorization, setPageCountAuthorization] = useState(0);
  
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageAppointment, setCurrentPageAppointment] = useState(0);
  const [currentPageAuthorization, setCurrentPageAuthorization] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const [widthScreen, setWidthScreen] = useState(0);

  var today = new Date();
  // var lastSixMonths = new Date(today);

  //const today = formatDate(new Date(), "extense");


  // const [startDate, setStartDate] = useState(
  //   lastSixMonths.setDate(lastSixMonths.getDate() - 60),
  // );
  
  // const [endDate, setEndDate] = useState(new Date());

  // const [openDateEnd, SetOpenDateEnd] = useState(false);
  // const [openDateStart, SetOpenDateStart] = useState(false);
  const [uuidHopistal, setUuidHospital] = useState(false);
  const [hospitalsListNoPagination, setHospitalsListNoPagination] = useState(
    [],
  );

  const [notificationList, setNotificationList] = useState([]);

  const handleChildClick = () => {
    getAppointmentList();
  };

  // const refreshClock = () => {
  //   setCurrentHour(formatHour(new Date()));
  // };


  const handleOnChangeHospital = (uuidChanged) => {
    setUUIDUser(uuidChanged);
  };


  async function getUserLogged() {
    if (!user) {
      try {
        const result = await getUser(user.id);
        setUserLogged(result.users);
      } catch (error) {}
    } else {
      setUserLogged(user);
    }
  }

  async function listHospitalNotification() {

    setNotificationList(await getHospitalNotification());

  }


  async function getAuthorizationList() {
    
    if (!uuidUser) {
      return;
    }
    try {

      const params = {
        pageNo: currentPageAuthorization ? currentPageAuthorization + 1 : 1,
        pageSize: itemsPerPage,
        Order: "DESC",
      };

      // if (!uuidUser) {
      //   getDate();
      // }

      const response = await listAuthorization(uuidUser,user.role_id, params);
      setShowAuthorization(response?.Authorization?.[0]?.authorization_list);
      setPageCountAuthorization(response?.Authorization[0]?.total_pages);

    } catch (error) {
      console.error(error);
    }
  }


  async function getAppointmentList() {
    if (!uuidUser) {
      return;
    }

    try {

      const params = {
        pageNo: currentPageAppointment ? currentPageAppointment + 1 : 1,
        pageSize: itemsPerPage,
        Order: "DESC",
      };

      const response = await listAppointment(uuidUser,user.role_id, params);

      setShowAppointment(response?.Appointment?.[0]?.appointment_list);
      setPageCountAppointment(response?.Appointment[0]?.total_pages);   

      console.log("response?.Appointment[0]?.total_pages", response)

    } catch (error) {
      console.error(error);
    }
  }

  async function getHospitalList() {
    try {
      const response = await getHospitalsList();
      setHospitalsListNoPagination(response);
    } catch (error) {}
  }

  async function getUserHospitalsList() {
    try {
      const response = await getUserHospitalList();
      setHospitalsListNoPagination(response);
    } catch (error) {
      console.error(error);
    }
  }

  function handleOpenModal() {

    onOpen();

  }


  function getDate() {
    if (user?.role_id === 2) {
      if(user?.hospitals.length === 1){
        setUUIDUser(user?.hospitals[0].uuid);
      }      
    }    
    if (user?.role_id === 1) {
      setUUIDUser(uuidHopistal);
    }
    if (user?.role_id === 3) {
      setUUIDUser(user?.hash);
    }
    if (user?.role_id === 4) {
      setUUIDUser(user?.uuid_doc);
    }
  }


  // async function getUserLogged() {

  //   if (!user) {
  //     try {
  //       const result = await getUser(user.id);
  //       setUserLogged(result.users);
  //     } catch (error) {}
  //   } else {
  //     setUserLogged(user);
  //   }
  // }


  useEffect(() => {
    if(!uuidUser){
      getDate()
    }
    getAuthorizationList();

  },[])

  useEffect(() => {
    if (
      user?.cod_pf ||
      user?.hash ||
      user?.hospitals?.[0]?.uuid ||
      uuidHopistal ||
      user?.uuid_doc
    ) {
      getDate();
    }

    if (user?.role_id === 1) {
      getHospitalList();
    }

    if (user?.role_id === 2) {
      getUserHospitalsList();

    }
    
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    getAppointmentList();
  },[currentPageAppointment])


  useEffect(() => {
    getAuthorizationList();
    
  },[currentPageAuthorization])

  useEffect(() => {
    getAuthorizationList();
    getAppointmentList();    
    getUserLogged();
  },[uuidUser])

  useEffect(() => {
    getUserLogged();

    listHospitalNotification();
  }, []) 

  useEffect(() => {

    handleOpenModal();
  }, [notificationList]) 

  


  return (
    <Flex backgroundColor="#F7F8FC" minH="100vh" minW="100vw">
      <Flex width="220px" id="sidebar" className="sidebar">
        <SideBar />
      </Flex>

     {/* // <Button onClick={handleOpenModal}>Teste</Button> */}

      <Box w="85%" p="24px 88px 22px 24px">
        <Flex alignContent="center" alignItems="center">
          <InternalHeader  onChangeHospital={handleOnChangeHospital} uuidUser={uuidUser} setUUIDUser={setUUIDUser} showHospital={true}/>
        </Flex>

        <Box
          m="18px 0"
          p="10px 20px"
          border="1px solid #CED4DA"
          borderRadius="10px"
          backgroundColor="var(--cinza-bg)"
        >
          <Tabs variant='soft-rounded' colorScheme='green'>
            <TabList>
              { (
                  (user?.role_id === 1) 
                  || (user?.permissoes.findIndex(item => item.id.toString() === '1')) >= 0
                  || (user?.permissoes.findIndex(item => item.id.toString() === '2')) >= 0

                )                  
                  &&

                    <Tab 
                      textColor="#F3953F" 
                      fontWeight={800} 
                      id="tabAgendamento">Agendamento</Tab>

              }

              <Tab textColor="#F3953F" fontWeight={800} id="tabAutorizacao">Autorização</Tab>

              {
                (
                  user?.role_id === 1 || user?.role_id === 3 ||
                  
                  user?.permissoes.findIndex(item => item.id.toString() === '1') >= 0  ||
                  user?.permissoes.findIndex(item => item.id.toString() === '3') >= 0 
                )
                &&
                <Tab textColor="#F3953F" fontWeight={800} id="tabResultado">Resultado</Tab>              
              }
              
            </TabList>
            <TabPanels>
  
              {
                (
                  (user?.role_id === 1 ) 
                  || (user?.permissoes.findIndex(item => item.id.toString() === '1')) >= 0
                  || (user?.permissoes.findIndex(item => item.id.toString() === '2')) >= 0

                )      
                &&
                <TabPanel>
                  <GridAppointment 
                    showAppointment={showAppointment} 
                    pageCountAppointment={pageCountAppointment}
                    currentPageAppointment={currentPageAppointment}
                    setCurrentPageAppointment={setCurrentPageAppointment}  
                    onChildClick ={handleChildClick}
                  /> 
                </TabPanel>
              }
              
              <TabPanel>
                <GridAuthorization 
                  showAuthorization={showAuthorization} 
                  pageCountAuthorization={pageCountAuthorization}
                  currentPageAuthorization={currentPageAuthorization}
                  setCurrentPageAuthorization={setCurrentPageAuthorization}
                />
              </TabPanel>
                {
                  (
                    (user?.role_id === 1 || user?.role_id === 3) 
                    || (user?.permissoes.findIndex(item => item.id.toString() === '1')) > -1
                    || (user?.permissoes.findIndex(item => item.id.toString() === '3')) > -1 

                  )

                  &&
                    <TabPanel>
                      <GridAttendance 
                        uuidSelected={uuidUser}
                        itemsPage={6}
                      />
                    </TabPanel>
              
                }
              
            </TabPanels>
          
          
          </Tabs>
        </Box>
      </Box>
      {

        (user.role_id === 2 && notificationList.length > 0)   && (

          <ModalNotification 
            notificationList={notificationList} 
            onClose={onClose} 
            isOpen={isOpen} 
            
          />


        )

      }

    </Flex>
  );
}

export default Dashboard;
