import styled from "styled-components";

export default function CheckboxInput({
  labelDescription = "Descrição do label",
  inputValue = "Valor padrão do input",
  onCheckboxChange = null,
  id = "id_do_input_checkbox",
  autoFocus = false,
}) {
  function handleInputChange({ currentTarget }) {
    if (onCheckboxChange) {
      onCheckboxChange();
    }
  }

  return (
    <>
      <BlockCheckbox>
        <input
          autoFocus={autoFocus}
          id={id}
          className="border p-1"
          type="checkbox"
          value={inputValue}
          onChange={handleInputChange}
        />

        <label className="test-sm mb-1" htmlFor="inputName">
          {labelDescription}
        </label>
      </BlockCheckbox>
    </>
  );
}

const BlockCheckbox = styled.div`
  input {
    margin-right: 10px;
  }

  label {
    font-size: 13px;
    line-height: 14px;
    color: #000000;
  }
`;
