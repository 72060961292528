export const FormatDate = function (item) {
  let newDate = new Date(item);
  return newDate.toLocaleDateString("pt-BR");
};

export function FormatHour(item) {
  let newDate = new Date(item);
  return newDate.toLocaleTimeString("pt-BR");
}

export function FormatFulldate(item) {
  let newDate = new Date(item);
  return newDate.toLocaleString("pt-BR");
}
export function FormatDateSimple(item) {
  let newDate = new Date(item);
  const day =
    newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate();
  const month =
    newDate.getMonth() <= 8
      ? `0${newDate.getMonth() + 1}`
      : `${newDate.getMonth() + 1}`;
  const year = newDate.getFullYear();
  const dateAltered = `${year}${month}${day}`;

  return dateAltered;
}
