import { HospitalsContext } from "../contexts/Hospitals";
import { useContext } from "react";

export function useHospitals() {
  const context = useContext(HospitalsContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}
