import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
    scrollbar-color:blue;
  }

  * {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', 'Roboto';
    box-sizing: border-box;
    --green-button : #198754;
    --gray-dark: #343A40;
    --green-senne:#b9bd5c;
    --orange-senne: #F3953F;
    --blue-senne :#0DCAF0;
    --cinza-card:#D7D7D7;
    --cinza-bg :#F7F8FC;
    --black-50 :#00000080;
    --orange-senne-secundary :#EC6726;
    --orange-hover :#FDEFE2;
    ::-webkit-scrollbar {
      max-width: 6px;               /* width of the entire scrollbar */
      max-heigth:3px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: var(--black-50);    /* color of the scroll thumb */
      border-radius: 20px;       /* roundness of the scroll thumb */
      max-width:3px;
      max-heigth:3px;
      
    }

  }


  
  body {
    background-color: #FFF;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }
  body::-webkit-scrollbar {
    width: 6px;               /* width of the entire scrollbar */
  }
  
  body::-webkit-scrollbar-track {
    background: var(--cinza-card);        /* color of the tracking area */
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: var(--black-50);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    
  }

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  select {
    -webkit-appearance: none;
    appearance: none;
  }

  .container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    position: relative;
    z-index: 1;
  }

  .color-orange{
    color: #EC6726;
  }
  .color-white{ 
    color: #FFFFFF;
  }
  .color-senne{
   color:#B9BD5C;
  }
  .mt-40{
    margin-top: 40px!important;
  }
  .mb-20{
    margin-bottom: 20px
  }
  .text-center{
    text-align: center;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .col-md-6 {
    
    width: 100%;
    max-width: 50%;
    @media screen and (max-width: 800px) {
      max-width: 100%;
    }
  }
  .col-md-8 {
    width: 100%;
    max-width: 66.33%;
    @media screen and (max-width: 800px) {
      max-width: 100%;
    }
  }

  .col-md-9 {
    width: 100%;
    max-width: 75%;
    @media screen and (max-width: 800px) {
      max-width: 100%;
    }
  }
  .col-md-4 {
    width: 100%;
    max-width: 33.3%;
    @media screen and (max-width: 800px) {
      max-width: 100%;
    }
  }
  .col-md-3 {
    width: 100%;
    max-width: 25%;
    @media screen and (max-width: 800px) {
      max-width: 100%;
    }
  }

  .only-desktop {
    display: block;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  .only-mobile {
    display: none;
    @media screen and (max-width: 800px) {
      display: block;
    }
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .align-items-center {
    display: flex;
    align-items: center;
  }

  .justify-content-center {
    display: flex;
    justify-content: center;
  }

  .justify-content-center{
    display: flex;
    justify-content: center;
  }

  .scroll-vertical {
    display: flex;
    overflow-x: auto;
    width: 100%;
  }

  .card {
    background-color: #FFF;
    border-radius: 10px;
    padding: 16px;
    width: 100%;
    margin: 16px 0;
    box-shadow: 0px 0px 30px -20px #333;
  }

  @media screen and (min-width: 700px) {
    .scroll-vertical {
      overflow-x: hidden;
      justify-content: center;
    }
  }

  .toast-message {
    background-color: #198754;
    color:white;
    font-size: 20px;
    width: 340px;
    height: 150px;
    padding: 30px 20px;
}



`;

export default GlobalStyle;
