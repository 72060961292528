import { useState } from "react";
import { Flex, Text, Img } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";

import DoctorStepTwo from "./doctorStepTwo";

import { GoBackButton, RegisterButton } from "../../../components/Buttons";
import TemplateRegister from "../../../components/TemplateRegister";
import FirstLetter from "../../../components/FirstLetter";
import TextSelect from "../../../components/TextSelect";
import TextInput from "../../../components/TextInput";

import Medico from "../../../images/medico.svg";

import BrazilianStateList from "../../../data/BrazilianStateList";

const schema = yup.object().shape({
  name: yup.string().required("O campo nome obrigatório"),
  email: yup
    .string()
    .email("Email inválido")
    .required("O campo email é obrigatório"),
  crm: yup
    .string("CRM obrigatório")
    .max(6)
    .required("O campo CRM é obrigatório"),
  uf: yup.string().required("O campo UF é obrigatório"),
});
export default function RegisterDoctor() {
  const [steps, setSteps] = useState(0);
  const [props, setProps] = useState({});

  function submit(values) {
    const { name, email, crm, uf } = values;

    const valuesApi = {
      name: FirstLetter(name),
      email: email,
      crm: `${crm}/${uf}`,
    };

    setProps(valuesApi);
    setSteps(1);
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  if (steps === 0) {
    return (
      <TemplateRegister>
        <Content>
          <Flex
            flexDirection="column"
            backgroundColor="white"
            p="20px 46px"
            borderRadius="8px"
            w="100%"
          >
            <form onSubmit={handleSubmit(submit)}>
              <Flex
                flexDirection="column"
                alignContent="center"
                alignItems="center"
                mb="25px"
                gap="10px"
              >
                <Flex
                  w="100%"
                  justifyContent="center"
                  gap="1.5em"
                  alignContent="center"
                  alignItems="center"
                >
                  <Img w="42px" src={Medico} alt="" />
                  <Text
                    w="150px"
                    as="strong"
                    color="var(--orange-senne-secundary)"
                  >
                    Médicos
                  </Text>
                </Flex>
                <h1 fontSize="40px">Faça seu Cadastro</h1>
              </Flex>

              <Flex gap="10px" justifyContent="center" alignItems="center">
                <TextInput
                  isError={errors.crm}
                  register={{ ...register("crm") }}
                  labelDescription="Digite seu CRM"
                  InputPlaceholder="Digite seu CRM"
                  maskInput="999999"
                  name="crm"
                  id="crm"
                />
                <TextSelect
                  isError={errors.uf}
                  register={{ ...register("uf") }}
                  labelDescription="UF"
                  name="uf"
                  id="uf"
                  titleOption="Escolha o UF"
                  options={BrazilianStateList}
                />
              </Flex>
              <Flex flexDirection="column">
                <TextInput
                  isError={errors.name}
                  register={{ ...register("name") }}
                  labelDescription="Nome Completo"
                  InputPlaceholder="Nome Completo"
                  name="name"
                  id="name"
                />
                <TextInput
                  isError={errors.email}
                  register={{ ...register("email") }}
                  labelDescription="Digite seu email"
                  InputPlaceholder="Digite seu email"
                  name="email"
                  id="email"
                />
              </Flex>

              <Flex
                mt="50px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Link to="/cadastro">
                  <GoBackButton />
                </Link>

                <RegisterButton type="submit">Continuar</RegisterButton>
              </Flex>
            </form>
          </Flex>
        </Content>
      </TemplateRegister>
    );
  }

  return <DoctorStepTwo props={props} />;
}
const Content = styled.div`
  padding: 105px 0;
  color: var(--gray-dark);
  h1 {
    margin: 10px 0;
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    line-height: 36px;
    font-weight: bold;
    font-weight: 700;
  }
  h2 {
    font-size: 1rem;
    font-weight: 600;
  }
  p {
    line-height: 24px;
    font-size: 16px;
    margin: 16px 0;
  }
  > div {
  }
  a {
    text-decoration: none;
  }
`;
