import { useState, useEffect } from "react";
import { Flex, Text, Img, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";

import { GoBackButton, RegisterButton } from "../../../components/Buttons";
import TemplateRegister from "../../../components/TemplateRegister";
import TextSelect from "../../../components/TextSelect";
import TextInput from "../../../components/TextInput";
import Loading from "../../../components/Loading";
import Terms from "../../../components/Term.jsx";

import { useRegister } from "../../../hooks";

import AgrePolicy from "../../../images/Política de Privacidade_PortalSenne.pdf";
import AgreTerms from "../../../images/Termo de Uso_Portal Senne.pdf";
import Medico from "../../../images/medico.svg";

const schema = yup.object().shape({
  especialidade: yup.string().required("Especialidade médica obrigatória"),
  cellphone: yup.string().when("typePhone", {
    is: "1",
    then: yup
      .string()
      .trim()
      .required("O campo telefone é obrigatório!")
      .matches(/^\(\d{2}\) \d{1} \d{4}-\d{4}$/, "Telefone inválido"),
  }),
  phone: yup.string().when("typePhone", {
    is: "2",
    then: yup
      .string()
      .trim()
      .required("O campo telefone é obrigatório!")
      .matches(/^\(\d{2}\) \d{4}-\d{4}$/, "Telefone inválido"),
  }),
  policy: yup
    .boolean()
    .required("leia e aceite os termos antes de prosseguir."),
  termsOfNotice: yup.boolean(),
  termsOfContact: yup.boolean(),
});
export default function RegisterDoctorStepTwo(props) {
  const [loading, setLoading] = useState(false);

  const Navigate = useNavigate();
  const [especialidades, setEspecialidades] = useState();
  const [PhoneNumber, setPhoneNumber] = useState("1");
  const { createDoctor, speciality } = useRegister();

  async function getEspecialidades() {
    try {
      const response = await speciality();
      let speciality_list = response?.data?.map((item) => {
        return { name: item.desc_specialty, id: item.desc_specialty };
      });
      setEspecialidades(speciality_list);
    } catch (error) {
      console.error(error);
    }
  }

  async function submit(values) {
    try {
      setLoading(true);

      delete values.typePhone;

      const valuesApi = {
        ...props.props,
        ...values,
      };

      const response = await createDoctor(valuesApi);

      if (response.status === 200) {
        Navigate("/cadastro-solicitado-medico");
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  function openpdf(item) {
    window.open(item, "_blank");
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    getEspecialidades();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TemplateRegister>
        {loading && <Loading />}
        <Content>
          <Flex
            flexDirection="column"
            backgroundColor="white"
            p="26px 46px"
            borderRadius="8px"
            w="100%"
          >
            <form onSubmit={handleSubmit(submit)}>
              <Flex
                flexDirection="column"
                alignContent="center"
                alignItems="center"
                mb="25px"
                gap="10px"
              >
                <Flex
                  w="100%"
                  justifyContent="center"
                  gap="1.5em"
                  alignContent="center"
                  alignItems="center"
                >
                  <Img w="42px" src={Medico} alt="" />
                  <Text
                    w="150px"
                    as="strong"
                    color="var(--orange-senne-secundary)"
                  >
                    Médicos
                  </Text>
                </Flex>
                <h1 fontSize="40px">Faça seu Cadastro</h1>
              </Flex>
              <TextSelect
                isError={errors.especialidade}
                register={{ ...register("especialidade") }}
                labelDescription=" Especialidade Médica"
                titleOption="Escolha sua especialidade"
                name="especialidade"
                id="eespecialidadem"
                options={especialidades}
              />


                <TextInput
                  isError={errors.cellphone}
                  register={{ ...register("cellphone") }}
                  labelDescription="Qual é o seu celular?"
                  InputPlaceholder="Digite seu celular"
                  maskInput="(99) 9 9999-9999"
                  name="cellphone"
                  id="cellphone"
                />

              <Flex
                justifyItems="stretch "
                alignContent="center"
                alignItems="center"
                gap="10px"
                w="600px"
              >
                <Terms
                  register={{ ...register("policy") }}
                  isError={errors.policy}
                >
                  <Text p="0">
                    Li e aceito a
                    <Link to="" onClick={() => openpdf(AgrePolicy)}>
                      <Text as="strong"> Política de Privacidade </Text>
                    </Link>
                    e os
                    <Link to="" onClick={() => openpdf(AgreTerms)}>
                      <Text as="strong"> Termos de uso</Text>
                    </Link>
                    .
                  </Text>
                </Terms>
              </Flex>
              <Flex
                justifyItems="stretch "
                alignContent="center"
                alignItems="center"
                gap="10px"
                w="600px"
              >
                <Terms
                  register={{ ...register("termsOfNotice") }}
                  isError={errors.termsOfNotice}
                >
                  <Text p="0">
                    Deseja receber notícias e informações nos canais de
                    comunicação?
                  </Text>
                </Terms>
              </Flex>
              <Flex
                justifyItems="stretch "
                alignContent="center"
                alignItems="center"
                gap="10px"
                w="600px"
              >
                <Terms
                  register={{ ...register("termsOfContact") }}
                  isError={errors.termsOfContact}
                >
                  <Text p="0">
                    Você permite que a nossa equipe entre em contato para a
                    discussão de caso e informe de resultados alterados?
                  </Text>
                </Terms>
              </Flex>
              <Flex
                mt="50px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Link to="/cadastro">
                  <GoBackButton />
                </Link>
                <RegisterButton type="submit">Continuar</RegisterButton>
              </Flex>
            </form>
          </Flex>
        </Content>
      </TemplateRegister>
    </>
  );
}
const Content = styled.div`
  padding: 105px 0;
  color: var(--gray-dark);
  h1 {
    margin: 10px 0;
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    line-height: 36px;
    font-weight: bold;
    font-weight: 700;
  }
  h2 {
    font-size: 1rem;
    font-weight: 600;
  }
  p {
    line-height: 24px;
    font-size: 16px;
    margin: 16px 0;
  }
  > div {
  }
  a {
    text-decoration: none;
  }
`;
