import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Text,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Img,
  Tooltip,
  Input,
  ListItem,
  UnorderedList,
  useMediaQuery
} from "@chakra-ui/react";
import { useNavigate } from "react-router";

import { Link, useParams } from "react-router-dom";

import { Pagination } from "../../components/Pagination";
import { BreadCrumbs } from "../../components/Breadcrumb2";
import InternalHeader from "../../components/InternalHeader";
import SideBar from "../../components/SideBar";

import { useExams } from "../../hooks";
import { useAuth } from "../../hooks";

import { formatDate } from "../../helpers";

import FileCheck from "../../images/file-earmark-check.svg";
import Observacoes from "../../images/observacoes.svg";
import Imprimir from "../../images/imprimir.svg";
import DownloadIcon from "../../images/downloadIcon.svg";
import ArrowBackIcon from "../../images/arrowLeft.svg";

function Detalhes() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const {
    treatmentExams,
    detailsExams,
    completeReport,
    pacientProtocol,
    treatmentUnitExams,
    createObservation,
    showObservation,
   // patientExamsDetails,
   // patientTreatmentExams,
    patientCompleteReport,
  } = useExams();
  const { uuid, id: numberTreatment } = useParams();

  const [onOpen, setOnOpen] = useState();
  const [text, setText] = useState();
  const itemsPerPage = 10;
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [exams, setExams] = useState();
  const [r_id, setR_id] = useState();
  const [numatendimento, setNumatendimento] = useState();
  const [textOservation, setTextObservation] = useState();
  const [details_Exams, setDetails_Exams] = useState();

  const [isMobile] = useMediaQuery("(max-width: 768px)") 


  const [uuidUser, setUUIDUser] = useState();

  const handleOnChangeHospital = (uuidChanged) => {
    setUUIDUser(uuidChanged);
  };


  async function getDetailsExams() {
    try {
      const params = {
        pageNo: currentPage ? currentPage + 1 : 1,
        pageSize: itemsPerPage,
        Order: "DESC",
      };

      const response = await detailsExams(uuid, numberTreatment);

      setNumatendimento(response?.atendimento?.[0]?.numatendimento);
      setR_id(response?.atendimento?.[0]?.r_id);
      setDetails_Exams(response?.atendimento?.[0]);

      const response_exams = await treatmentExams(
        uuid,
        numberTreatment,
        params,
      );

      // console.log("response_exams", response_exams)

      setPageCount(response_exams?.atend_exames?.[0]?.total_pages);

      setExams(response_exams?.atend_exames?.[0]?.lista_atend_exames);
    } catch (error) {
      console.error(error);
    }
  }

  // MINHA FUNÇÃO -.-
  // async function getPatientDetails() {
  //   try {
  //     const params = {
  //       pageNo: currentPage ? currentPage + 1 : 1,
  //       pageSize: itemsPerPage,
  //       Order: "DESC",
  //     };

  //     // const response = await patientExamsDetails(numberTreatment);
  //     const response = await patientExamsDetails(numberTreatment);

  //     setNumatendimento(response?.atendimento?.[0]?.numatendimento);

  //     setR_id(response?.atendimento?.[0]?.r_id);
  //     setDetails_Exams(response?.atendimento?.[0]);

  //     const response_exams = await patientTreatmentExams(
  //       uuid,
  //       numberTreatment,
  //       params,
  //     );

  //     setPageCount(response_exams?.atend_exames?.[0]?.total_pages);
  //     setExams(response_exams?.atend_exames?.[0]?.lista_atend_exames);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  async function getReport() {
    try {
      let response = {};


      if (user.role_id === 3) {
        response = await patientCompleteReport(uuid, numberTreatment, r_id);
      } else {
        response = await completeReport(uuid, numberTreatment, r_id);
      }

      window.open(response);
    } catch (error) {
      console.error(error);
    }
  }

  async function getexamsUnit(seqexame) {
    try {
      const response = await treatmentUnitExams(
        uuid,
        numberTreatment,
        r_id,
        seqexame,
      );
      window.open(response);
    } catch (error) {
      console.error(error);
    }
  }

  async function getProtocol() {
    try {
      const data = {
        login_protocol: details_Exams?.login,
        passtemp: details_Exams?.passtemp,
        r_id: r_id,
        name: details_Exams?.nomepaciente,
      };
      const response = await pacientProtocol(data);
      window.open(response.data, "_blank");
    } catch (error) {
      console.error(error);
    }
  }

  async function observation(value) {
    try {
      setOnOpen(false);
      const data = {
        numatendimento: numatendimento,
        id_usuario: "",
        data: "",
        observation: value,
      };

      await createObservation(data);
    } catch (error) {
      console.error(error);
    }
  }
  async function showObservationAPI() {
    try {
      const response = await showObservation(numatendimento);
      setTextObservation(response?.data);
    } catch (error) {
      console.error(error);
    }
  }

  function OpenModal() {
    try {
      onOpen === false ? setOnOpen(true) : setOnOpen(false);
      if (numatendimento) {
        showObservationAPI();
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
  //  if (user.role_id === 3) {

  //     getPatientDetails();
  //     return;
  //   }
   getDetailsExams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberTreatment, currentPage]);

  return (
    <Flex width="100%" minHeight="100vh" backgroundColor="#F7F8FC">

      {
        !isMobile && (
            <Flex width="220px">
              <SideBar />
            </Flex>
        )
      }

      <Box 
        // p="3em 3em 3em 3em" 
        p={ isMobile ? "3em 1em 3em 1em" : "3em 3em 3em 3em" }
        w="100%"
      >
        <Flex alignContent="center" alignItems="center">
          <InternalHeader 
            onChangeHospital={handleOnChangeHospital} 
            setUUIDUser={setUUIDUser} 
            uuidUser={uuidUser} 
            isMobile={isMobile}
          />
        </Flex>

        <BreadCrumbs title="Detalhes do atendimento" isMobile={isMobile}/>

        <Box
          mt="2em"
          padding="1.5em 1.5em"
          border="1px solid #CED4DA"
          borderRadius="10px"
          backgroundColor="#fff"
        >
          {/* <Box mb="15px" as="strong" color="#00000080" fontSize="1.625rem">
            <Text p="10px 20px">Detalhes do atendimento</Text>
          </Box> */}
          {/* <Flex></Flex> */}

          {

            !isMobile ? (
              <TableContainer w="100%" marginLeft={ isMobile ? "-2em" : "0"} display="block"  overflowX="hidden" >
              <Table variant="unstyled">
                <Thead>
                  <Tr >
                    <Th marginLeft={ isMobile ? "-1em" : "0"}>Nome do Paciente</Th>
                    <Th > Médico Solicitante </Th>
                    <Th 
                      p="5 24px"
                      textAlign={"center"}
                    >
                      Número de
                      <br />
                      atendimento
                    </Th>
                    <Th 
                      p="5 24px"
                      textAlign={"center"}
                    > Data de coleta</Th>
                    <Th p="5 24px"
                      textAlign={"center"}
                    > Previsão de<br /> Conclusão </Th>
                  </Tr>
                </Thead>
  
                <Tbody>
                  <Tr>
                    <Td
                      p="0 24px"
                      maxW="300px"
                      w="250px"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      <Tooltip
                        hasArrow
                        label={details_Exams?.nomepaciente}
                        bg="gray.300"
                        color="black"
                      >
                        <Text>{details_Exams?.nomepaciente} </Text>
                      </Tooltip>
                    </Td>
                    <Td
                      p="0 24px"
                      maxW="300px"
                      w="250px"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      <Tooltip
                        hasArrow
                        label={details_Exams?.nomemedico}
                        bg="gray.300"
                        color="black"
                      >
                        <Text>{details_Exams?.nomemedico} </Text>
                      </Tooltip>
                    </Td>
                    <Td 
                      p="0 24px"
                      w="100px"
                      maxW="100px"
                      textAlign={"center"}
                    >{details_Exams?.numatendimento}</Td>
                    <Td 
                      p="0 24px"
                      w="100px"
                      maxW="100px"
                      textAlign={"center"}
                    >{formatDate(details_Exams?.dataentrada)}</Td>
                    <Td 
                      p="0 24px"
                      w="100px"
                      maxW="100px"
                      textAlign={"center"}
                    >{formatDate(details_Exams?.dataresultado)}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>

            ) : (
              <Flex flexDirection="column">
                <Flex flexDirection="column">
                  <Text fontWeight="bold">Paciente</Text>
                  <Text>{details_Exams?.nomepaciente} </Text>
                </Flex>        
                <Flex flexDirection="column">
                  <Text fontWeight="bold">Médico</Text>
                  <Text>{details_Exams?.nomemedico.substring(0,20)} </Text>
                </Flex>
                <Flex  flexDirection="row" gap={20}>
                  <Flex flexDirection="column">
                    <Text fontWeight="bold">Atend</Text>
                    <Text>{details_Exams?.numatendimento} </Text>  
                  </Flex>    
                  <Flex flexDirection="column">
                      <Text fontWeight="bold">Data Coleta</Text>
                      <Text>{formatDate(details_Exams?.dataentrada)} </Text>

                    </Flex>
                </Flex>
              </Flex>

            )


          }



          <Box w={["100%", "50%", "25%"]}  >
            <Flex  gap="10px" p={!isMobile && "24px"} w={[1, 1 /2, 1/4]} flexDirection={ isMobile ? "column" : "row"} >
              <Link to="">
                <Flex
                  border="solid 1px var(--orange-senne)"
                  borderRadius="4px"
                  width="250px"
                  h="80px"
                  p="13px 0"
                  alignItems="center"
                >
                  <Flex h="100%" w="32px" ml="10px">
                    <img src={FileCheck} alt="" />
                  </Flex>

                  <Flex
                    p="0 14px"
                    flexDirection="column"
                    onClick={() => getReport()}
                  >
                    <Text fontSize="15px" as="strong">
                      Laudo Completo
                    </Text>
                    <Text fontSize="11px">Clique aqui pra visualizar</Text>
                  </Flex>
                </Flex>
              </Link>


              {(user.role_id === 1 || user.role_id === 2) && (
                <>
                  <Link to="" onClick={() => getProtocol()}>
                    <Flex
                      border="solid 1px var(--orange-senne)"
                      borderRadius="4px"
                      width="250px"
                      p="13px 5px"
                      h="80px"
                      alignContent="center"
                      alignItems="center"
                    >
                      <Flex h="100%" w="32px" ml="10px">
                        <img src={Imprimir} alt="" />
                      </Flex>
                      <Flex p="0 14px" flexDirection="column"  >
                        <Text fontSize="15px" as="strong" w="100%">
                          Imprimir Protocolo
                          <br />
                        </Text>
                        <Text fontSize=".75rem" w="140px">
                          Imprimir ou enviar p/ o paciente
                        </Text>
                      </Flex>
                    </Flex>
                  </Link>
                </>
              )}
              {/* <Link to="">
                <Flex
                  border="solid 1px var(--orange-senne)"
                  borderRadius="4px"
                  width="229px"
                  p="17px 0"
                  onClick={OpenModal}
                >
                  <Flex h="100%" w="32px" ml="10px">
                    <img src={Observacoes} alt="" />
                  </Flex>
                  <Text p="0 14px" fontSize="15px" as="strong">
                    Observações
                  </Text>
                </Flex>
              </Link> */}
            </Flex>




          </Box>
          <Box m={isMobile ? "1em 0": "0em 1.4em"} alignContent="center" h="100%">
            <Text
              fontSize={isMobile ? "0.9em" : "1.5em" }
              as="strong"
              color="var(--orange-senne-secundary)"
              fontWeight="700"
              // textAlign={isMobile ? "center" : "left"}
              textAlign="center"
            >
              RESULTADOS DISPONÍVEIS PARA CONSULTA
            </Text>
          </Box>


          {onOpen && (
            <Flex
              position="absolute"
              top="0"
              left="0"
              w="100vw"
              h="100%"
              zIndex="2"
              backgroundColor="#55787850"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Flex
                flexDirection="column"
                backgroundColor="white"
                w="500px"
                p="30px 20px"
                gap="10px"
              >
                <Text as="strong">Observações</Text>
                {user.role_id === 1 && (
                  <>
                    <Input
                      w="100%"
                      h="134px"
                      type="text"
                      fontSize="0.825rem"
                      htmlSize=""
                      onChange={(e) => setText(e.target.value)}
                      placeholder="Descreva, caso necessário, alguma restrição, observação ou situação que precise de atenção."
                    />
                  </>
                )}

                <Flex h="100%" overflow="scroll" borderRadius="5px">
                  <UnorderedList>
                    {textOservation?.message?.[0]?.updated_at && (
                      <ListItem>
                        {`${formatDate(
                          textOservation?.message?.[0]?.updated_at,
                        )} : ${textOservation?.message?.[0]?.observation}`}
                      </ListItem>
                    )}
                  </UnorderedList>
                </Flex>
                <Flex w="100%" gap="10px" justifyContent="space-between">
                  {user.role_id === 1 && (
                    <Button
                      onClick={() => observation(text)}
                      backgroundColor="var(--orange-senne)"
                      color="white"
                      w="121px"
                      _hover={{ opacity: 0.7 }}
                    >
                      Salvar
                    </Button>
                  )}
                  <Button
                    onClick={() => setOnOpen(false)}
                    backgroundColor="var(--gray-dark)"
                    color="white"
                    w="121px"
                    _hover={{ opacity: 0.7 }}
                  >
                    Fechar
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          )}
          <Box
            // m="30px 24px"

            m={ isMobile ? "30px 0" : "30px 24px"}
            p="0px"
            // borderTop="1px solid var(--cinza-card)"
            borderRadius="4px"
            // maxH="407px !important"
            overflowY="scroll"
          >
            <Table
              
            >
              <Thead
                backgroundColor="var(--cinza-bg)"
                border="1px solid var(--cinza-card)"
                
              >
                <Tr>
                  <Th border="1px solid var(--cinza-card)">
                    Exames
                  </Th>
                  <Th
                    border="1px solid var(--cinza-card)"
                    w="350px"
                    maxW="350px"
                    textAlign={"center"}
                  >
                    Status
                  </Th>
                  <Th 
                    border="1px solid var(--cinza-card)"
                    textAlign={"center"}
                  >
                    Previsão de Conclusão
                  </Th>
                  <Th 
                    border="1px solid var(--cinza-card)"
                    textAlign={"center"}
                  >
                    Ações
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {exams?.map((item, index) => {
                  return (
                    <Tr key={index}>
                      <Td border="1px solid var(--cinza-card)"
                       onClick={
                        item.status === "Liberado" || item.status === "Alterado"
                        ? () => getexamsUnit(item?.seqexame)
                        : () => console.log("exame nao liberado")
                       }
                      >
                        {item?.nomeexame} 
                      </Td>
                      <Td border="1px solid var(--cinza-card)">
                        <Flex
                          backgroundColor={`#${item?.corfundo}`}
                          w="100%"
                          borderRadius="30px"
                          alignContent="center"
                          alignItems="center"
                          justifyContent="center"
                          p="5px"
                          
                          color={`#${item?.corfonte}`}
                        >
                          <Text>{item?.status}</Text>
                        </Flex>
                      </Td>
      
                      <Td 
                        border="1px solid var(--cinza-card)"
                        textAlign={"center"}
                        
                      >
                        {formatDate(item?.dataprevista)}
                      </Td>
                      <Td 
                        border="1px solid var(--cinza-card)"
                        textAlign={"center"}
                        
                      >
                        <Button
                          onClick={
                            item.status === "Liberado" || item.status === "Alterado"
                              ? () => getexamsUnit(item?.seqexame)
                              : () => console.log("exame nao liberado")
                          }
                          as="flex"
                          gap="9px"
                          w="100%"
                          h="100%"
                          color={
                            item.status === "Liberado" || item.status === "Alterado" ? "Black" : "#D7D7D7"
                          }
                          justifyContent="start"
                          backgroundColor="white"
                          _hover="none"
                          cursor={item.status === "Liberado" || item.status === "Alterado" ? "pointer" : ""}
                        >
                          <Img src={DownloadIcon} color="#D7D7D7" />
                          <Text>Baixar</Text>
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
          <Flex
            m="0 0 20px 0"
            flexDirection={{}}
            w="100%"
            alignContent="center"
            alignItems="center"
            p={isMobile? "0px 0px" : "0px 24px"}
            backgroundColor="white"
            justifyContent="space-between"
          >
            <Button
              backgroundColor="#343A40"
              color="white"
              _hover={{ opacity: "0.8" }}
              onClick={() => navigate("/resultados-exames")}
            >
              <Flex
                alignContent="center"
                alignItems="center"
                justifyContent="space-between"
                gap="15px"
                p="0"
              >
                <Img src={ArrowBackIcon} h="18px" />

                <Text m="15px 0">Voltar</Text>
              </Flex>
            </Button>
            <Pagination
              pageCount={pageCount}
              changePage={setCurrentPage}
              pageSelected={currentPage}
            />
            {/* <Flex gap="16px">
              <Button
                w="189px"
                fontWeight="400"
                fontSize="1rem"
                backgroundColor="#0dcaf0"
                _hover={{ opacity: "0.8" }}
                color="white"
                p="0"
              >
                Novo Agendamendo
              </Button>
              <Button
                w="189px"
                fontWeight="400"
                fontSize="1rem"
                backgroundColor="#f3953f"
                _hover={{ opacity: "0.8" }}
                color="white"
                p="0"
              >
                Solicitar acréscimo
              </Button>
            </Flex> */}
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
}

export default Detalhes;
