import { GroupsContext } from "../contexts/Groups";
import { useContext } from "react";

export function useGroups() {
  const context = useContext(GroupsContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}
