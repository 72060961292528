import React from "react";
import styled from "styled-components";

function Menu({ items }) {
  return (
    <Content>
      {items.map((item) => (
        <li key={item.title}>
          <a href={item.link} target="_blank" rel="noreferrer">
            {item.title}
          </a>
        </li>
      ))}
    </Content>
  );
}

export default Menu;

const Content = styled.ul`
  a {
    color: white;
    text-decoration: none;
    font-weight: 400;
  }
`;
