import React from "react";

// import { Container } from './styles';

const UserSidebarIcon: React.FC = () => {
  return (
    <>
      <svg
        width="21"
        height="22"
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.53125 2.75C6.3572 2.75 6.19028 2.82243 6.06721 2.95136C5.94414 3.0803 5.875 3.25516 5.875 3.4375C5.875 3.61984 5.94414 3.7947 6.06721 3.92364C6.19028 4.05257 6.3572 4.125 6.53125 4.125H10.4688C10.6428 4.125 10.8097 4.05257 10.9328 3.92364C11.0559 3.7947 11.125 3.61984 11.125 3.4375C11.125 3.25516 11.0559 3.0803 10.9328 2.95136C10.8097 2.82243 10.6428 2.75 10.4688 2.75H6.53125ZM12.4375 11C12.4375 12.094 12.0227 13.1432 11.2842 13.9168C10.5458 14.6904 9.54429 15.125 8.5 15.125C7.45571 15.125 6.45419 14.6904 5.71577 13.9168C4.97734 13.1432 4.5625 12.094 4.5625 11C4.5625 9.90598 4.97734 8.85677 5.71577 8.08318C6.45419 7.3096 7.45571 6.875 8.5 6.875C9.54429 6.875 10.5458 7.3096 11.2842 8.08318C12.0227 8.85677 12.4375 9.90598 12.4375 11Z"
          className="icon"

        />
        <path
          d="M3.90625 0C3.03601 0 2.20141 0.362164 1.58606 1.00682C0.970702 1.65148 0.625 2.52582 0.625 3.4375V19.25C0.625 19.9793 0.901562 20.6788 1.39384 21.1945C1.88613 21.7103 2.55381 22 3.25 22H13.75C14.4462 22 15.1139 21.7103 15.6062 21.1945C16.0984 20.6788 16.375 19.9793 16.375 19.25V3.4375C16.375 2.52582 16.0293 1.65148 15.4139 1.00682C14.7986 0.362164 13.964 0 13.0938 0L3.90625 0ZM1.9375 3.4375C1.9375 2.89049 2.14492 2.36589 2.51413 1.97909C2.88335 1.5923 3.3841 1.375 3.90625 1.375H13.0938C13.6159 1.375 14.1167 1.5923 14.4859 1.97909C14.8551 2.36589 15.0625 2.89049 15.0625 3.4375V18.2806C14.7476 18.0168 14.4061 17.7899 14.044 17.6041C12.952 17.0321 11.2077 16.5 8.5 16.5C5.79231 16.5 4.048 17.0321 2.956 17.6041C2.59391 17.7899 2.25236 18.0168 1.9375 18.2806V3.4375Z"
          className="icon"


        />
      </svg>
    </>
  );
};

export default UserSidebarIcon;
