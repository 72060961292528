import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUsers } from "../../hooks";
import SideBar from "../../components/SideBar";
import { Pagination } from "../../components/Pagination";
import { FormatFulldate } from "../../components/Format";

import {
  Flex,
  Box,
  Text,
  Input,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import InternalHeader from "../../components/InternalHeader";
import Breadcrumb from "../../components/Breadcrumb";
import { GreenButton } from "../../components/Buttons/index";

import Search from "../../images/search.svg";

function Statitic() {
  const navigate = useNavigate();
  const { getLogsAll } = useUsers();
  const [usersToShow, setUsersToShow] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");

  const itemsPerPage = 10;
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  
  const [uuidUser, setUUIDUser] = useState();
  
  async function handleOnChangeHospital(uuidChanged) {
    setUUIDUser(uuidChanged);
  };  

  async function getLogsUserAll(event) {
    try {
      const params = {
        limit: itemsPerPage,
        page: event ? event.selected + 1 : 1,
        name: searchFilter,
      };
      const response = await getLogsAll();
      setUsersToShow(response?.Logs?.data);

      setPageCount(response?.Logs?.last_page);
      setCurrentPage(event ? event.selected : 0);
    } catch (error) {
      return console.log(error);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      getLogsUserAll();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter]);

  useEffect(() => {
    getLogsUserAll();
  }, []);

  return (
    <Flex width="100%" maxHeight="100vh" backgroundColor="#F7F8FC">
      <Flex width="220px">
        <SideBar />
      </Flex>
      <Box p="24px 88px 22px 24px" w="calc(100% - 200px)">
        <Flex alignContent="center" justifyContent="space-between" w="100%">
          <InternalHeader width="673px"  setHospital={handleOnChangeHospital} setUUIDUser={setUUIDUser} uuidUser={uuidUser} />
        </Flex>
        <Breadcrumb page="Gerenciamento de Usuários/ Estatísticas de ações" />
        <Box
          mt="44px"
          p="15px 20px"
          border="1px solid #CED4DA"
          borderRadius="10px"
          backgroundColor="#fff"
        >
          <Flex justifyContent="space-between">
            <Flex
              w="100%"
              p="0 12px"
              fontSize="0.75rem"
              border="1px solid #CED4DA"
              color="#8F8F8F"
              borderRadius="4px"
              alignContent="center"
              alignItems="center"
              gap="10px"
            >
              <img src={Search} alt="" />
              <Text as="strong">Pesquisar:</Text>
              <Input
                fontSize="0.75rem"
                padding="0"
                _focus={{
                  border: "none",
                }}
                border="none"
                placeholder="Digite aqui"
                onChange={(event) => setSearchFilter(event.target.value)}
              />
            </Flex>
          </Flex>

          <Flex
            mt="20px"
            pt="20px"
            borderTop="1px solid var(--orange-senne)"
            fontSize="13px"
            lineHeight="14px"
          >
            <TableContainer
              w="100%"
              borderRadius="8px"
              border="var(--cinza-card) 1px solid"
              // height="380px"
              maxH="300px"
              overflowY="scroll"
            >
              <Table variant="simple">
                <Thead backgroundColor="var(--cinza-bg)">
                  <Tr>
                    {/* <Th></Th> */}
                    <Th borderLeft="var(--cinza-card) 1px solid">
                      Nome do usuário
                    </Th>
                    <Th borderLeft="var(--cinza-card) 1px solid">Dia/Hora </Th>
                    <Th borderLeft="var(--cinza-card) 1px solid" maxW="100px">
                      End. de IP
                    </Th>
                    <Th borderLeft="var(--cinza-card) 1px solid">Grupo</Th>
                    <Th borderLeft="var(--cinza-card) 1px solid">Hospital</Th>
                    <Th borderLeft="var(--cinza-card) 1px solid">Atividade</Th>
                    <Th borderLeft="var(--cinza-card) 1px solid">
                      Nº Atendimento
                    </Th>
                    <Th borderLeft="var(--cinza-card) 1px solid">Ações</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {usersToShow?.map((user, index) => (
                    <Tr
                      key={index}
                      _hover={{ backgroundColor: "var(--orange-hover)" }}
                    >
                      {/* <Td p="0 8px">
                        <Flex w="100%" justifyContent="center">
                          <Checkbox />
                        </Flex>
                      </Td> */}
                      <Td p="0 8px" borderLeft="1px solid var(--cinza-card)">
                        {user?.userName}
                      </Td>
                      <Td
                        overflowY
                        maxH="15px"
                        p="0 8px"
                        borderLeft="1px solid var(--cinza-card)"
                      >
                        {FormatFulldate(user?.time_action)}
                      </Td>
                      <Td p="0 8px" borderLeft="1px solid var(--cinza-card)">
                        {user?.ip_user}
                      </Td>
                      <Td p="15px 8px" borderLeft="1px solid var(--cinza-card)">
                        {user?.groupName}
                      </Td>
                      <Td p="15px 8px" borderLeft="1px solid var(--cinza-card)">
                        {user?.hospitalName}
                      </Td>
                      <Td p="15px 8px" borderLeft="1px solid var(--cinza-card)">
                        {user?.log_description}
                      </Td>
                      <Td
                        p="15px 8px"
                        borderLeft="1px solid var(--cinza-card)"
                        textAlign="center"
                        color="var(--blue-senne)"
                      >
                        <Link
                          to={`/resultados-exames/${user?.uuid}/${user?.numatendimento}`}
                        >
                          <u>{user?.numatendimento}</u>
                        </Link>
                      </Td>
                      <Td p="0 8px" borderLeft="1px solid var(--cinza-card)">
                        <Menu>
                          <MenuButton
                            style={{
                              backgroundColor: "transparent",
                              display: "flex",
                              margin: "0 auto",
                            }}
                            as={IconButton}
                            icon={
                              <GreenButton
                                style={{
                                  padding: "0 8px 8px",
                                  width: "40px",
                                }}
                              >
                                ...
                              </GreenButton>
                            }
                          />
                          <MenuList>
                            <Link
                              fontSize="12px"
                              to={
                                `/users/${user?.id_user}` || navigate("/users")
                              }
                            >
                              <MenuItem fontSize="12px">Ver Perfil</MenuItem>
                            </Link>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
          <Flex mt="18px" justifyContent="end">
            <Pagination
              pageCount={pageCount}
              pageSelected={currentPage}
              changePage={getLogsUserAll}
            />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
}

export default Statitic;
