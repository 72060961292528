import { useAuth } from "../../../hooks";
import TemplateLogin from "../../../components/TemplateLogin";
import styled from "styled-components";
import TextInput from "../../../components/TextInput";
import PasswordInput from "../../../components/TextInput";
import { Link, useNavigate } from "react-router-dom";
import { PrimaryButton, GoBackButton } from "../../../components/Buttons";
import Card from "../../../components/Card";
import { Flex } from "@chakra-ui/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import cliente from "../../../images/cliente.svg";
const schema = yup.object().shape({
  password: yup.string().required("Digite a senha"),
  login_protocol: yup.string().required("Digite seu Login"),
});
export default function AtendimentoLogin() {
  const { signIn } = useAuth();
  const navigate = useNavigate();

  async function submit(values) {
    try {
      const response = await signIn(values, 5);

      if (response.message === "Usuário não pode logar nesse local") {
        navigate("/login");
      }
      
      navigate(`/resultados-exames/${response?.data?.user?.cod_pf}`);
    } catch (error) {
      console.log(error);
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  return (
    <>
      <TemplateLogin image="">
        <Content>
          <Card>
            <Flex
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              gap="37px"
              mb="71px"
            >
              <img src={cliente} alt="" />
              <h1>Atendimento</h1>
            </Flex>
            <Flex
              as="form"
              onSubmit={handleSubmit(submit)}
              flexDirection="column"
            >
              <TextInput
                isError={errors.login_protocol}
                register={{ ...register("login_protocol") }}
                labelDescription="Login"
                InputPlaceholder="Digite seu Login"
                name="login_protocol"
                onChange={(e) => setValue("login_protocol", e.target.value)}
              />
              <PasswordInput
                isError={errors.password}
                register={{ ...register("password") }}
                labelDescription="Senha"
                InputPlaceholder="Senha"
                name="password"
                type="password"
                autoFocus
                password
              />
              {/* <Flex alignItems="center" justifyContent="space-between">
                <CheckboxInput labelDescription="Lembrar Senha"></CheckboxInput>
                <Link to="/nova-senha" target="_blank">
                  <Text
                    color="var( --orange-senne-secundary)"
                    textDecoration="underline"
                  >
                    Esqueci a Senha
                  </Text>
                </Link>
              </Flex> */}
              <PrimaryButton autoFocus type="submit">
                Entrar
              </PrimaryButton>
            </Flex>
            <Flex width="100%" justifyContent="center">
              <Link to="/login">
                <GoBackButton />
              </Link>
            </Flex>
          </Card>
        </Content>
      </TemplateLogin>
    </>
  );
}
const Content = styled.div`
  padding: 72px 80px;

  h1 {
    font-size: 36px;
    line-height: 36px;
    font-weight: bold;
  }
  p {
    line-height: 24px;
    font-size: 16px;
    margin: 16px 0;
  }
  > div {
    margin: 24px 0 0;
  }
  a {
    text-decoration: none;
  }

  @media (max-width: 600px) {
    padding: 15px;
    h1 {
      font-size: 1.5rem;
    }
    div {
      margin: 12px 0 20px 0;
    }
    input {
      margin-bottom: 10px;
    }
  }
`;
