import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Flex, Box, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";

import { CancelButton, PrimaryResultsButton } from "../../components/Buttons";
import { BreadCrumbs } from "../../components/Breadcrumb2";
import InternalHeader from "../../components/InternalHeader";
import TextInput from "../../components/TextInput";
import SideBar from "../../components/SideBar";

import { useHospitals } from "../../hooks";

import ProfileImage from "../../images/profile.png";

const schema = yup.object().shape({
  cnpj: yup.string().min(18, "CNPJ Obrigatório").required(),
  phone: yup.string(),
});

const HospitalProfile = () => {
  const navigate = useNavigate();
  const { id_group: groupId } = useParams();
  const { id_hospital: hospitalid } = useParams();
  const { ShowHospitalsForId, EditHospital, EditImageHospital } =
    useHospitals();
  const [listUnit, setListUnit] = useState();
  const [photoBase64, setPhotoBase64] = useState();
  const [GroupImage, setGroupImage] = useState(null);

  async function getHospitalInfo() {
    try {
      const response = await ShowHospitalsForId(hospitalid);
      setListUnit(response);
    } catch (error) {}
  }

  function getBase64() {
    let document = "";
    let reader = new FileReader();
    reader.readAsDataURL(GroupImage);
    reader.onloadend = function (e) {
      document = reader.result;
      setPhotoBase64(document);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  useEffect(() => {
    if (GroupImage) {
      getBase64();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GroupImage]);

  useEffect(() => {
    getHospitalInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospitalid]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const submit = async (data) => {
    const new_values = {
      ...data,
      name: listUnit?.name,
      grupo_id: groupId,
    };
    const formData = new FormData();
    formData.append("image", GroupImage);
    formData.append("id_hospital", hospitalid);

    const response = await EditHospital(hospitalid, new_values);
    if (GroupImage) {
      await EditImageHospital(formData);
      if (response?.status === 200) {
        navigate(`/gerenciamento-senne/gerenciamento-grupos`);
      }
    }
    if (response?.status === 200) {
      navigate(`/gerenciamento-senne/gerenciamento-grupos`);
    }
  };

  return (
    <>
      {
        <Flex width="100%" maxH="100vh" backgroundColor="var(--cinza-bg)">
          <Flex width="220px">
            <SideBar />
          </Flex>
          <Box p="24px 88px 22px 24px" w="calc(100% - 200px)">
            <Flex alignContent="center" justifyContent="space-between" w="100%">
              <InternalHeader width="673px" />
            </Flex>

            <BreadCrumbs />

            <Flex
              mt="24px"
              border="1px solid var(--cinza-card)"
              borderRadius="8px"
              p="46px 50px"
              backgroundColor="white"
              flexDirection="row"
            >
              <Flex
                width="250px"
                alignItems="start"
                justifyContent="space-between"
                flexDirection="column"
                borderRight="1px solid var(--orange-senne)"
                pr="50px"
                mr="30px"
              >
                <CardImage>
                  <img
                    src={photoBase64 ?? listUnit?.image ?? ProfileImage}
                    alt=""
                  />
                </CardImage>
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  m="0 0"
                  w="160px"
                  pb="24px"
                >
                  <Text color="var(--orange-senne)"></Text>
                  {/* <Text>{listAdmin?.Group?.name}</Text> */}
                  {/* <Text as="strong">{listAdmin?.Group?.name}</Text> */}
                </Flex>
                <PrimaryResultsButton maxH="30px">
                  {/* <img src={addNewFoto} alt="" /> */}
                  <Text pl="30px">Adicionar nova foto</Text>
                  <input
                    type="file"
                    name="profileImage"
                    accept="image/png, image/jpeg"
                    onChange={(event) => setGroupImage(event.target.files[0])}
                    style={{
                      position: "absolute",
                      width: "100%",
                      heigth: "100%",
                      opacity: 0,
                      cursor: "pointer",
                      zIndex: "999",
                    }}
                  />
                </PrimaryResultsButton>
              </Flex>
              <Flex width="calc(100% - 200px)" flexDirection="column" pl="16px">
                <Text fontSize="1.9rem" as="strong">
                  Informações de Hospital
                </Text>
                <form onSubmit={handleSubmit(submit)}>
                  <Flex alignItems="center" gap="65px">
                    <TextInput
                      labelDescription="Nome do grupo"
                      isError={errors.name}
                      name="name"
                      defaultValue={listUnit?.name}
                      value={listUnit?.name || null}
                      InputPlaceholder={listUnit?.name}
                      disabled
                      register={{ ...register("name") }}
                    />
                    <TextInput
                      labelDescription="Email"
                      register={{ ...register("email") }}
                      defaultValue={listUnit?.email}
                      value={listUnit?.email || "N/A"}
                      disabled
                    />
                  </Flex>
                  <Flex alignItems="center" gap="65px">
                    <TextInput
                      isError={errors.phone}
                      register={{ ...register("phone") }}
                      name="phone"
                      labelDescription="Telefone"
                      maskInput="(**) *****-****"
                      defaultValue={listUnit?.phone}
                      value={listUnit?.phone || "N/A"}
                      disabled
                      InputPlaceholder={listUnit?.phone}
                    />
                    <TextInput
                      isError={errors.cnpj}
                      register={{ ...register("cnpj") }}
                      name="cnpj"
                      labelDescription="CNPJ"
                      maskInput="**.***.***/****-**"
                      defaultValue={listUnit?.cnpj}
                      value={listUnit?.cnpj || "N/A"}
                      disabled
                      InputPlaceholder={listUnit?.cnpj}
                    />
                  </Flex>

                  <Flex gap="13px" justifyContent="end">
                    <Box w="86px">
                      <CancelButton w="86px">Cancelar</CancelButton>
                    </Box>
                    <Box w="68px">
                      <PrimaryResultsButton type="submit">
                        Salvar
                      </PrimaryResultsButton>
                    </Box>
                  </Flex>
                </form>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      }
    </>
  );
};
export default HospitalProfile;

const CardImage = styled.div`
  display: flex;
  margin: 0 auto 16px;
  padding: 8px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 0px 24.7967px rgba(0, 0, 0, 0.1);
  img {
    width: 130px;
    height: 130px;
    border-radius: 60px;
    object-fit: cover;
  }
`;
