import React from "react";

import { 
  Text,
  Modal, 
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalHeader

} from "@chakra-ui/react";


const ModalNotification = ({ notificationList, isOpen, onClose }) => {
  
  // const { onClose, isOpen } = useDisclosure();

  return (

    
      <>
        <Modal onClose={onClose} isOpen={isOpen} size="xl">
          <ModalOverlay>
            <ModalContent>
              <ModalHeader style={{textAlign: "center"}}>
                <Text color={"#990507"} fontSize={22}>Resultados Críticos</Text>
                <ModalCloseButton />
              </ModalHeader>
              <ModalBody style={{textAlign: "center"}}>
                
                {

                  notificationList.length === 1 ?
                    <Text marginBottom={8}>Existem pacientes com resultado crítico em</Text> :
                    <Text marginBottom={8}>Existem pacientes com resultado crítico em</Text> 
                }
                

                {
                  notificationList.length > 0 &&
                  notificationList.map((notification, index) => (  

                  <Text 
                    key={index}
                    fontWeight={"bold"}
                  >
                      {notification.hospital}
                  </Text>
                ))}

                <Text marginTop={10}>Favor entrar em contato no telefone</Text>
                <Text >para mais informações</Text>
                <Text fontWeight={800} marginTop={8}>(11) 3286-8989</Text> 
                <Text fontWeight={800} marginBottom={10} >(19) 4141-7270</Text>
                

              </ModalBody>
            </ModalContent>
          </ModalOverlay>

        </Modal>
      </>


  );
};

export default ModalNotification;
