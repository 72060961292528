import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Flex, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";

import { PrimaryButton, GoBackButton } from "../../../components/Buttons";
import CheckboxInput from "../../../components/CheckboxInput";
import PasswordInput from "../../../components/TextInput";
import TemplateLogin from "../../../components/TemplateLogin";
import TextInput from "../../../components/TextInput";
import Loading from "../../../components/Loading";
import Card from "../../../components/Card";

import { useAuth } from "../../../hooks";

import Medico from "../../../images/medico.svg";

const schema = yup.object().shape({
  password: yup.string().required("Digite a senha"),
  email: yup.string().email().required("Digite o e-mail"),
});

export default function MedicoLogin() {
  const [loading, setLoading] = useState(false);

  async function submit(values) {
    try {
      setLoading(true);

      const response = await signIn(values, 4);


      if (response.message === "Usuário não pode logar nesse local") {
        navigate("/login");
      }

      if (response.status !== 200) {
        return;
      }

      if (response.data.type === "validate_cellphone") {
        let { cellphone, expire_date } = response.data;

        navigate("/validar/numero", {
          state: {
            cellphone: cellphone,
            expire_date: expire_date,
          },
        });
        return;
      }

      navigate("/resultados-exames");
      return;
    } catch (error) {
      console.log(error);
      return;
    } finally {
      setLoading(false);
    }
  }

  const { signIn } = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,

  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  return (
    <>
      <TemplateLogin image="">
        {loading && <Loading />}
        <Content>
          <Card>
            <Flex
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              gap="37px"
              mb="71px"
            >
              <img src={Medico} alt="" />
              <h1>Médico</h1>
            </Flex>
            <Flex
              flexDirection="column"
              as="form"
              onSubmit={handleSubmit(submit)}
            >
              <TextInput
                isError={errors.email}
                labelDescription="Login com seu e-mail cadastrado"
                InputPlaceholder="Email"
                register={{ ...register("email") }}
                name="email"
                type="email"
                id="email"
                onChange={(e) => setValue("email", e.target.value)}
              />
              <PasswordInput
                isError={errors.password}
                labelDescription="Senha"
                InputPlaceholder="Senha"
                register={{ ...register("password") }}
                name="password"
                type="password"
                id="password"
                onChange={(e) => setValue("password", e.target.value)}
                password
                autoFocus
              />
              <Flex alignItems="center" justifyContent="space-between">
                <CheckboxInput labelDescription="Lembrar Senha"></CheckboxInput>
                <Link to="/nova-senha" target="_blank">
                  <Text
                    color="var( --orange-senne-secundary)"
                    textDecoration="underline"
                  >
                    Esqueci a Senha
                  </Text>
                </Link>
              </Flex>
              <PrimaryButton autoFocus type="submit">
                Entrar
              </PrimaryButton>
            </Flex>
            <Flex width="100%" justifyContent="center">
              <Link to="/login">
                <GoBackButton />
              </Link>
            </Flex>
          </Card>
        </Content>
      </TemplateLogin>
    </>
  );
}
const Content = styled.div`
  padding: 72px 80px;

  h1 {
    font-size: 36px;
    line-height: 36px;
    font-weight: bold;
  }
  p {
    line-height: 24px;
    font-size: 16px;
    margin: 16px 0;
  }
  > div {
    margin: 24px 0 0;
  }
  a {
    text-decoration: none;
  }
  @media (max-width: 414px) {
    padding: 15px;
    h1 {
      font-size: 1.5rem;
    }
    div {
      margin: 12px 0 20px 0;
    }
    input {
      margin-bottom: 10px;
    }
  }
`;
