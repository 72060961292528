import React from "react";
import logo from "../images/logo.svg";
import styled from "styled-components";
import Menu from "./Menu";

export default function Navbar() {
  const links = [
    { link: "https://senneliquor.com.br/unidades/", title: "Unidades" },
    { link: "https://senneliquor.com.br/contato/", title: "Fale Conosco" },
    {
      link: "https://senneliquor.com.br/tipo/hospitalis-e-clinicas/",
      title: "Rede Credenciada",
    },
    { link: "https://senneliquor.com.br/quem-somos/", title: "Quem Somos" },
  ];
  return (
    <Nav>
      <div className="container row head-nav">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <Menu items={links} />
      </div>
    </Nav>
  );
}

const Nav = styled.nav`
  margin: 15px 0;
  width: 100%;
  padding: 16px 24px;
  position: fixed;
  top: 0;
  z-index: 999;

  .head-nav {
    justify-content: space-between;
    align-items: center;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    list-style: none;

    li {
      color: white;

      a {
        cursor: pointer;
      }
    }
  }
  @media (max-width: 600px) {
    position: absolut;
    width: 100%;
    padding: 16px 24px;
    top: 0;
    z-index: 999;

    ul {
      // background-color: rgb(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      list-style: none;
      font-size: 0.7rem;
      padding: 10px 0;

      li {
        color: black;

        a {
          cursor: pointer;
        }
      }
    }
  }

  @me;
`;
