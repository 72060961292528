import React from "react";

// import { Container } from './styles';

const LogoIcon: React.FC = () => {
  return (
    <>
      <svg
        width="138"
        height="57"
        viewBox="0 0 138 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.678 21.9978C23.067 31.01 18.9333 40.5453 13.3667 47.7193L13.1074 48.0539L13.772 48.5771L14.036 48.2519C17.0243 44.5942 19.0841 41.2052 23.3027 38.6552C25.9752 37.0385 28.8834 36.2325 31.9943 36.0675C34.2568 35.9497 36.524 35.6339 38.7157 35.0541L39.748 34.7808L38.8147 34.2717C35.8452 32.655 34.0965 29.5865 33.0596 26.4898C30.7264 19.5185 23.9437 16.5867 17.0573 16.073L16.0816 15.9976L16.6897 16.7611C17.9529 18.3449 18.9663 20.0983 19.678 21.9978Z"
          fill="#F3953F"
        />
        <path
          d="M2.82223 19.3913C1.39404 22.4173 0.484341 25.6979 0.144971 29.068C-0.524343 35.7659 1.10652 42.8172 5.4005 48.1199C8.64337 52.1216 10.9058 52.0745 14.0403 48.233C19.8002 40.8093 23.9763 31.0006 20.4742 21.6961C19.8944 20.1548 17.8959 16.945 17.9289 16.9874C13.2956 16.6433 8.20502 16.9167 3.92518 18.8869L2.82223 19.3913Z"
          fill="#EC6726"
        />
        <path
          d="M38.3998 35.0164C41.6002 36.7603 45.2814 36.5482 48.6846 35.5537C58.1634 32.7869 62.9947 26.2776 59.3417 16.5678C58.2388 13.636 56.5702 10.9776 54.4303 8.68688C45.9178 -0.405421 32.0648 -1.98915 20.7384 2.2247C13.0318 5.09049 6.87131 10.9399 3.36448 18.3071L3.18537 18.6842L2.82715 19.3912C6.28213 13.3155 7.29081 13.4899 10.232 10.8881C10.5573 10.6005 10.8919 10.3177 11.236 10.0444C19.6118 3.33708 31.7773 0.777662 41.5389 6.08975C43.9758 7.41895 46.1157 9.18651 47.855 11.3406C50.4993 14.607 52.0358 18.6794 51.178 22.8839C49.9242 29.0585 44.2492 32.7162 38.494 34.2386L37.4618 34.512L38.3998 35.0164Z"
          fill="#ABB056"
        />
        <path
          d="M16.9905 16.9215C23.5233 17.407 30.0232 20.1267 32.2433 26.7586C33.3557 30.0721 35.2316 33.2867 38.3991 35.0166L38.5499 35.0967L38.7149 35.0543C44.7953 33.447 50.6918 29.5536 52.0163 23.049C52.926 18.5901 51.3281 14.2678 48.5189 10.8034C46.7042 8.56453 44.4795 6.72156 41.9436 5.34051C31.8851 -0.13655 19.3472 2.45586 10.698 9.37996C10.3445 9.66277 10.0004 9.95501 9.66101 10.252L9.22266 10.6432L9.73171 10.9354C12.376 12.4484 14.7846 14.3715 16.6888 16.7566L16.8019 16.9027L16.9905 16.9215Z"
          fill="#B9BD5C"
        />
        <path
          d="M2.82227 19.3913L3.92051 18.8823C8.20034 16.912 13.3003 16.6575 17.9431 17.0016C15.822 14.0839 13.0741 11.8733 9.89248 10.0492C6.89942 12.6982 4.5474 15.7336 2.82227 19.3913Z"
          fill="#D15D20"
        />
        <path
          d="M25.3382 50.6038C24.2447 47.229 24.4238 46.5974 28.0909 47.2997C31.758 48.002 31.692 48.6524 29.4154 51.3815C27.1624 54.0871 26.4223 53.9457 25.3382 50.6038Z"
          fill="#DADADA"
        />
        <path
          d="M19.3617 54.2097C13.7055 52.8758 12.9702 52.0934 17.0521 47.6014C21.134 43.1048 21.9918 43.7552 23.9008 49.2134C25.7956 54.6245 24.9707 55.5295 19.3617 54.2097Z"
          fill="#C6C6C6"
        />
        <path
          d="M70.4941 3.931C70.4941 7.31057 79.0066 6.05678 79.0066 11.2039C79.0066 14.131 76.5792 15.4507 73.506 15.4507C70.8523 15.4507 68.8161 14.197 67.9723 13.1317C67.8121 12.9055 67.7461 12.7452 67.7461 12.519C67.7461 12.0052 68.1656 11.5857 68.6841 11.5857C68.8773 11.5857 69.1366 11.6517 69.3958 11.9062C70.3998 12.9055 71.696 13.4852 73.506 13.4852C75.3489 13.4852 76.9374 12.8725 76.9374 11.1992C76.9374 7.88561 68.4248 9.20539 68.4248 3.92629C68.4248 1.25375 70.626 0.032959 73.3787 0.032959C75.5799 0.032959 77.2956 0.772975 78.2996 2.09275C78.3986 2.22001 78.5258 2.41327 78.5258 2.7055C78.5258 3.21927 78.1063 3.63877 77.5878 3.63877C77.4276 3.63877 77.1683 3.60577 76.9091 3.35125C76.0371 2.51696 75.0661 1.99848 73.3834 1.99848C71.9175 1.99848 70.4941 2.45097 70.4941 3.931Z"
          fill="#B9BD5C"
        />
        <path
          d="M87.2262 1.8713C84.4123 1.8713 82.8568 3.67185 82.6636 6.53764H91.3694C91.3694 4.21861 90.5586 1.8713 87.2262 1.8713ZM87.2262 0.00476074C92.4063 0.00476074 93.4056 4.09135 93.4056 6.2784C93.4056 7.21167 93.4056 8.33819 92.1094 8.33819H82.6589C82.6589 11.5245 84.5018 13.5843 87.5467 13.5843C89.4227 13.5843 90.5256 12.8773 91.4306 12.1985C91.6239 12.0713 91.7559 12.0053 92.0151 12.0053C92.5336 12.0053 92.9531 12.4248 92.9531 12.9385C92.9531 13.2921 92.7928 13.4853 92.5949 13.6786C92.1094 14.1593 90.4267 15.4791 87.5467 15.4791C83.2433 15.4791 80.5566 13.1318 80.5566 7.72544C80.5566 2.77157 83.0171 0.00476074 87.2262 0.00476074Z"
          fill="#B9BD5C"
        />
        <path
          d="M107.971 5.69859V14.2913C107.971 14.871 107.518 15.3188 106.934 15.3188C106.349 15.3188 105.897 14.8663 105.897 14.2913V6.11809C105.897 3.57753 105.152 1.83826 102.013 1.83826C98.8079 1.83826 97.7097 3.77078 97.7097 6.34434V14.2913C97.7097 14.871 97.2572 15.3188 96.6727 15.3188C96.0929 15.3188 95.6357 14.8663 95.6357 14.2913V1.2255C95.6357 0.645746 96.0882 0.197966 96.6727 0.197966C97.2572 0.197966 97.7097 0.65046 97.7097 1.2255V2.25304C98.3224 1.03225 99.9109 0 102.013 0C107 0.00471348 107.971 2.96478 107.971 5.69859Z"
          fill="#B9BD5C"
        />
        <path
          d="M123.214 5.69859V14.2913C123.214 14.871 122.762 15.3188 122.177 15.3188C121.593 15.3188 121.14 14.8663 121.14 14.2913V6.11809C121.14 3.57753 120.395 1.83826 117.256 1.83826C114.051 1.83826 112.953 3.77078 112.953 6.34434V14.2913C112.953 14.871 112.5 15.3188 111.916 15.3188C111.336 15.3188 110.879 14.8663 110.879 14.2913V1.2255C110.879 0.645746 111.331 0.197966 111.916 0.197966C112.5 0.197966 112.953 0.65046 112.953 1.2255V2.25304C113.566 1.03225 115.154 0 117.256 0C122.243 0.00471348 123.214 2.96478 123.214 5.69859Z"
          fill="#B9BD5C"
        />
        <path
          d="M131.821 1.8713C129.007 1.8713 127.452 3.67185 127.258 6.53764H135.964C135.964 4.21861 135.153 1.8713 131.821 1.8713ZM131.821 0.00476074C137.001 0.00476074 138 4.09135 138 6.2784C138 7.21167 138 8.33819 136.704 8.33819H127.254C127.254 11.5245 129.097 13.5843 132.141 13.5843C134.017 13.5843 135.12 12.8773 136.025 12.1985C136.219 12.0713 136.351 12.0053 136.61 12.0053C137.128 12.0053 137.548 12.4248 137.548 12.9385C137.548 13.2921 137.388 13.4853 137.19 13.6786C136.704 14.1593 135.021 15.4791 132.141 15.4791C127.838 15.4791 125.151 13.1318 125.151 7.72544C125.156 2.77157 127.612 0.00476074 131.821 0.00476074Z"
          fill="#B9BD5C"
        />
        <path
          d="M70.8475 18.5192V36.8924C70.8475 38.1792 71.267 38.7259 72.1437 38.7259C72.6622 38.7259 73.1147 39.1454 73.1147 39.6922C73.1147 40.2389 72.6622 40.6584 72.1437 40.6584C69.9755 40.6584 68.7783 39.3717 68.7783 36.8924V18.5192C68.7783 17.9725 69.2638 17.4917 69.8153 17.4917C70.395 17.4917 70.8475 17.9725 70.8475 18.5192Z"
          fill="#B9BD5C"
        />
        <path
          d="M77.9693 39.6262C77.9693 40.2059 77.5168 40.6537 76.9324 40.6537C76.3526 40.6537 75.8954 40.2012 75.8954 39.6262V26.5651C75.8954 25.9854 76.3479 25.5376 76.9324 25.5376C77.5168 25.5376 77.9693 25.9901 77.9693 26.5651V39.6262ZM75.7021 21.286C75.7021 20.3528 76.1546 19.9993 76.9324 19.9993C77.7101 19.9993 78.1626 20.3528 78.1626 21.286C78.1626 22.0591 77.7101 22.5068 76.9324 22.5068C76.1546 22.5116 75.7021 22.0591 75.7021 21.286Z"
          fill="#B9BD5C"
        />
        <path
          d="M92.1755 31.6793C92.1755 28.6249 90.3938 27.2062 87.9051 27.2062C84.9262 27.2062 83.3425 29.0727 83.3425 33.0933C83.3425 37.0526 84.9592 38.9475 87.9051 38.9475C90.9453 38.9475 92.1755 36.1147 92.1755 36.1147V31.6793ZM81.2686 33.098C81.2686 27.8236 83.729 25.3444 87.9051 25.3444C91.5958 25.3444 94.2495 27.4371 94.2495 31.684V45.8433C94.2495 46.423 93.797 46.8708 93.2125 46.8708C92.628 46.8708 92.1755 46.4183 92.1755 45.8433V38.7259C91.4308 39.7535 89.8471 40.8187 87.7732 40.8187C83.6017 40.8187 81.2686 38.3724 81.2686 33.098Z"
          fill="#B9BD5C"
        />
        <path
          d="M99.91 26.5652V34.9646C99.91 37.0574 100.589 38.8579 103.86 38.8579C107.126 38.8579 107.81 37.0574 107.81 34.9646V26.5652C107.81 25.9854 108.295 25.5376 108.875 25.5376C109.427 25.5376 109.879 25.9901 109.879 26.5652V34.9646C109.879 38.0236 108.875 40.8187 103.86 40.8187C98.8778 40.8187 97.8408 38.0189 97.8408 34.9646V26.5652C97.8408 25.9854 98.2933 25.5376 98.8778 25.5376C99.4245 25.5329 99.91 25.9854 99.91 26.5652Z"
          fill="#B9BD5C"
        />
        <path
          d="M124.345 33.065C124.345 29.0114 122.629 27.2109 119.65 27.2109C116.671 27.2109 114.956 29.0114 114.956 33.065C114.956 37.1846 116.671 38.9522 119.65 38.9522C122.629 38.9522 124.345 37.1846 124.345 33.065ZM126.447 33.065C126.447 38.3441 123.859 40.8187 119.65 40.8187C115.474 40.8187 112.854 38.3394 112.854 33.065C112.854 27.7907 115.474 25.3444 119.65 25.3444C123.859 25.3444 126.447 27.7859 126.447 33.065Z"
          fill="#B9BD5C"
        />
        <path
          d="M131.821 27.9791C132.467 26.499 134.088 25.4055 136.318 25.4055C136.869 25.4055 137.322 25.858 137.322 26.4048C137.322 26.9515 136.869 27.404 136.318 27.404C133.339 27.404 131.821 29.4638 131.821 32.4239V39.6308C131.821 40.2105 131.368 40.6583 130.784 40.6583C130.204 40.6583 129.747 40.2058 129.747 39.6308V26.565C129.747 25.9853 130.2 25.5375 130.784 25.5375C131.368 25.5375 131.821 25.99 131.821 26.565V27.9791Z"
          fill="#B9BD5C"
        />
        <path
          d="M71.4413 51.1318C71.4413 51.1318 71.0265 50.1796 70.0037 50.1796C69.0139 50.1796 68.4671 50.816 68.4671 52.1499C68.4671 53.5026 68.9997 54.1295 70.0037 54.1295C70.8427 54.1295 71.4413 53.6535 71.4413 52.6259V51.1318ZM71.4413 50.2551V47.2573C71.4413 47.064 71.5922 46.9132 71.7901 46.9132C71.9881 46.9132 72.1389 47.064 72.1389 47.2573V52.6259C72.1389 54.0541 71.2481 54.7564 70.0037 54.7564C68.5991 54.7564 67.7695 53.9222 67.7695 52.1452C67.7695 50.3682 68.552 49.548 69.9566 49.548C70.6542 49.5528 71.1915 49.911 71.4413 50.2551Z"
          fill="white"
        />
        <path
          d="M76.4189 54.3605C76.4189 54.5537 76.268 54.7093 76.0701 54.7093C75.8721 54.7093 75.7213 54.5585 75.7213 54.3605V49.9675C75.7213 49.7743 75.8721 49.6187 76.0701 49.6187C76.268 49.6187 76.4189 49.7696 76.4189 49.9675V54.3605ZM75.6553 48.1858C75.6553 47.87 75.8061 47.7522 76.0701 47.7522C76.3293 47.7522 76.4848 47.87 76.4848 48.1858C76.4848 48.4451 76.334 48.5959 76.0701 48.5959C75.8061 48.6006 75.6553 48.4498 75.6553 48.1858Z"
          fill="white"
        />
        <path
          d="M83.1117 52.7438V52.2913H81.5751C80.8681 52.2913 80.3779 52.5836 80.3779 53.2105C80.3779 53.7714 80.7691 54.1296 81.6176 54.1296C82.3859 54.1296 83.1117 53.6677 83.1117 52.7438ZM80.5665 50.7547C80.458 50.8726 80.3826 50.9056 80.2837 50.9056C80.1093 50.9056 79.9678 50.7642 79.9678 50.5898C79.9678 50.5144 79.9914 50.4578 80.0197 50.4059C80.3261 49.9299 80.9105 49.5481 81.8297 49.5481C83.0929 49.5481 83.7905 50.2834 83.7905 51.3911V54.37C83.7905 54.5538 83.6396 54.7046 83.4511 54.7046C83.2673 54.7046 83.1117 54.5538 83.1117 54.37V53.9788C82.8384 54.4878 82.2067 54.7565 81.5563 54.7565C80.4015 54.7565 79.6709 54.205 79.6709 53.2105C79.6709 52.2583 80.4015 51.6833 81.5751 51.6833H83.1117V51.3911C83.1117 50.6558 82.6215 50.1986 81.825 50.1986C81.2499 50.2033 80.9011 50.373 80.5665 50.7547Z"
          fill="white"
        />
        <path
          d="M90.3558 51.3721C90.3558 50.6557 89.8986 50.1796 89.0596 50.1796C88.2206 50.1796 87.7634 50.6557 87.7634 51.3721C87.7634 52.0886 88.2206 52.5647 89.0596 52.5647C89.8986 52.5647 90.3558 52.0839 90.3558 51.3721ZM89.6158 54.4123H88.4845C87.8529 54.5207 87.5701 54.846 87.5701 55.2796C87.5701 55.9395 88.3337 56.1893 89.1916 56.1893C90.0541 56.1893 90.813 55.9395 90.813 55.2372C90.813 54.6386 90.3228 54.4123 89.6158 54.4123ZM90.2238 49.8214C90.4076 49.4868 90.648 49.2369 91.1948 49.2369C91.3692 49.2369 91.5106 49.3783 91.5106 49.5527C91.5106 49.7271 91.3692 49.8685 91.1948 49.8685C90.8696 49.8685 90.747 50.0335 90.6716 50.2032C90.9214 50.5049 91.0628 50.9055 91.0628 51.3721C91.0628 52.4751 90.3228 53.1915 89.0596 53.1915C88.593 53.1915 88.1876 53.0926 87.8859 52.9229C87.7681 53.0219 87.688 53.1397 87.688 53.3141C87.688 53.5403 87.8388 53.7666 88.494 53.7666H89.6158C90.6716 53.7666 91.52 54.2191 91.52 55.2372C91.52 56.3637 90.4972 56.7974 89.1774 56.7974C88.0556 56.7974 86.8584 56.4721 86.8584 55.2843C86.8584 54.7847 87.1742 54.3982 87.688 54.1908C87.2873 54.0588 87.0139 53.7383 87.0139 53.3141C87.0139 52.9889 87.1883 52.7296 87.4476 52.5552C87.1883 52.23 87.0564 51.8388 87.0564 51.3769C87.0564 50.2739 87.7964 49.5575 89.0596 49.5575C89.5074 49.5527 89.9174 49.6517 90.2238 49.8214Z"
          fill="white"
        />
        <path
          d="M98.807 51.4664V54.3558C98.807 54.549 98.6562 54.6999 98.4582 54.6999C98.2603 54.6999 98.1094 54.549 98.1094 54.3558V51.6078C98.1094 50.7547 97.8596 50.1702 96.8038 50.1702C95.7244 50.1702 95.3568 50.8207 95.3568 51.6879V54.3605C95.3568 54.5537 95.2059 54.7046 95.008 54.7046C94.81 54.7046 94.6592 54.5537 94.6592 54.3605V49.9628C94.6592 49.7696 94.81 49.6187 95.008 49.6187C95.2059 49.6187 95.3568 49.7696 95.3568 49.9628V50.3116C95.5642 49.9015 96.0968 49.5527 96.8038 49.5527C98.4771 49.5527 98.807 50.5473 98.807 51.4664Z"
          fill="white"
        />
        <path
          d="M103.729 48.6618L104.7 47.4929C104.784 47.3845 104.94 47.342 105.114 47.4929C105.279 47.6437 105.265 47.7851 105.147 47.903L104.092 48.9635C103.993 49.0625 103.894 49.0907 103.766 48.987C103.63 48.8786 103.653 48.7608 103.729 48.6618ZM105.831 52.1498C105.831 50.7876 105.256 50.1796 104.252 50.1796C103.253 50.1796 102.673 50.7876 102.673 52.1498C102.673 53.5356 103.248 54.1295 104.252 54.1295C105.251 54.1295 105.831 53.5356 105.831 52.1498ZM106.538 52.1498C106.538 53.9268 105.666 54.7611 104.252 54.7611C102.847 54.7611 101.966 53.9268 101.966 52.1498C101.966 50.3728 102.847 49.5527 104.252 49.5527C105.666 49.5527 106.538 50.3728 106.538 52.1498Z"
          fill="white"
        />
        <path
          d="M110.384 50.8725C110.384 52.0084 113.25 51.5889 113.25 53.3188C113.25 54.3039 112.434 54.747 111.397 54.747C110.506 54.747 109.818 54.3275 109.535 53.9692C109.479 53.8938 109.46 53.8373 109.46 53.7618C109.46 53.5874 109.601 53.446 109.776 53.446C109.842 53.446 109.927 53.4696 110.016 53.5544C110.356 53.8891 110.789 54.0871 111.397 54.0871C112.019 54.0871 112.552 53.8797 112.552 53.3188C112.552 52.2017 109.686 52.6495 109.686 50.8725C109.686 49.9722 110.426 49.5621 111.355 49.5621C112.095 49.5621 112.675 49.8119 113.009 50.255C113.042 50.2974 113.085 50.3634 113.085 50.4624C113.085 50.6368 112.943 50.7782 112.769 50.7782C112.712 50.7782 112.627 50.7688 112.538 50.6792C112.246 50.3964 111.916 50.2267 111.35 50.2267C110.86 50.222 110.384 50.3729 110.384 50.8725Z"
          fill="white"
        />
        <path
          d="M116.87 48.5677C116.87 48.3839 117.035 48.2236 117.218 48.2236C117.416 48.2236 117.567 48.3886 117.567 48.5677V49.7037H118.274C118.458 49.7037 118.599 49.8451 118.599 50.0289C118.599 50.2127 118.458 50.3541 118.274 50.3541H117.567V53.3425C117.567 53.8091 117.826 54.0542 118.199 54.0542C118.383 54.0542 118.538 54.1956 118.538 54.3794C118.538 54.5633 118.387 54.7047 118.199 54.7047C117.336 54.7047 116.87 54.1296 116.87 53.3425V50.3541H116.445C116.262 50.3541 116.12 50.2127 116.12 50.0289C116.12 49.8451 116.262 49.7037 116.445 49.7037H116.87V48.5677Z"
          fill="white"
        />
        <path
          d="M122.502 54.3605C122.502 54.5537 122.351 54.7093 122.153 54.7093C121.955 54.7093 121.804 54.5585 121.804 54.3605V49.9675C121.804 49.7743 121.955 49.6187 122.153 49.6187C122.351 49.6187 122.502 49.7696 122.502 49.9675V54.3605ZM121.738 48.1858C121.738 47.87 121.889 47.7522 122.153 47.7522C122.412 47.7522 122.568 47.87 122.568 48.1858C122.568 48.4451 122.417 48.5959 122.153 48.5959C121.894 48.6006 121.738 48.4498 121.738 48.1858Z"
          fill="white"
        />
        <path
          d="M128.012 49.5527C128.983 49.5527 129.516 50.0618 129.69 50.3022C129.742 50.3776 129.766 50.4342 129.766 50.5096C129.766 50.6934 129.624 50.8254 129.45 50.8254C129.342 50.8254 129.266 50.783 129.191 50.6981C129.026 50.5143 128.734 50.1891 128.017 50.1891C127.018 50.1891 126.48 50.8301 126.48 52.1593C126.48 53.5121 127.013 54.1389 128.017 54.1389C128.649 54.1389 129.03 53.8561 129.28 53.6723C129.356 53.6158 129.412 53.5969 129.488 53.5969C129.662 53.5969 129.803 53.7383 129.803 53.9127C129.803 54.0117 129.77 54.0871 129.662 54.1814C129.422 54.3746 128.898 54.7658 128.017 54.7658C126.612 54.7658 125.773 53.9316 125.773 52.1546C125.769 50.3729 126.608 49.5527 128.012 49.5527Z"
          fill="white"
        />
        <path
          d="M136.614 52.1499C136.614 50.7877 136.039 50.1796 135.035 50.1796C134.036 50.1796 133.456 50.7877 133.456 52.1499C133.456 53.5356 134.031 54.1295 135.035 54.1295C136.039 54.1295 136.614 53.5356 136.614 52.1499ZM137.321 52.1499C137.321 53.9268 136.449 54.7611 135.035 54.7611C133.63 54.7611 132.749 53.9268 132.749 52.1499C132.749 50.3729 133.63 49.5527 135.035 49.5527C136.449 49.5527 137.321 50.3729 137.321 52.1499Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default LogoIcon;
