import { UsersContext } from "../contexts/Users";
import { useContext } from "react";

export function useUsers() {
  const context = useContext(UsersContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}
