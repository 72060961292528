import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Flex, Box, Text } from "@chakra-ui/react";

import { CancelButton, PrimaryResultsButton } from "../../components/Buttons";
import { BreadCrumbs } from "../../components/Breadcrumb2";
import SideBar from "../../components/SideBar";
import InternalHeader from "../../components/InternalHeader";

import avatarGroup from "../../images/avatarGroup.svg";

import { useAuth, useHospitals } from "../../hooks";

const HospitalManager = () => {
  const navigate = useNavigate();
  const { id_group: groupId } = useParams();
  const { user } = useAuth();
  const { listHospital } = useHospitals();

  // eslint-disable-next-line no-unused-vars
  const [userGroupList, setUserGroupList] = useState([1, 2]);
  const [groupList, setGroupList] = useState();

  async function group_List_Senne() {
    try {
      const results = await listHospital(groupId);
      setGroupList(results?.[0]?.hospitals);
      if (user?.role_id === 2) {
        verificate(results?.Grupos?.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (user) {
      group_List_Senne();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function verificate(group_list) {
    let unique_groups_id = [
      ...new Set(user?.hospitals?.map((item) => item.grupo_id)),
    ];
    setUserGroupList(unique_groups_id);

    let grupos = [];
    for (let i = 0; i < group_list?.length; i++) {
      if (unique_groups_id.includes(group_list[i]?.id)) {
        grupos.push(group_list[i]);
      }
    }
    setGroupList(grupos);
  }

  return (
    <Flex width="100%" minHeight="100vh" backgroundColor="var(--cinza-bg)">
      <Flex width="220px">
        <SideBar />
      </Flex>
      <Box
        p={{ base: "24px 24px 22px 24px", md: "24px 88px 22px 24px" }}
        w="calc(100% - 165px)"
      >
        <Flex
          alignContent="center"
          justifyContent="space-between"
          w="100%"
          flexDirection={{ base: "column", md: "row" }}
          alignItems="center"
          gap="15px"
        >
          <InternalHeader width="100%" />
        </Flex>

        <BreadCrumbs
          title={
            groupId ? "Gerenciamento de Hospitais" : "Gerenciamentos de Grupos"
          }
        />

        <Box
          m="16px 88px 0 0"
          borderRadius="8px"
          backgroundColor="var(--cinza-bg)"
          flexDirection="row"
          width="100%"
          maxW="100%"
        >
          <Box
            p="20px 21px"
            backgroundColor="white"
            borderRadius="10px"
            border="1px solid var(--cinza-card)"
          >
            <Flex
              alignContent="center"
              alignItems="center"
              justifyContent="space-between"
              w="100%"
              gap="20px"
              flexDirection={{ base: "column", lg: "row" }}
            >
              {/* <Flex
                w="100%"
                h="34px"
                p="0 2px "
                border="1px solid var(--cinza-card)"
                color="#8F8F8F"
                borderRadius="4px"
                alignContent="center"
                alignItems="center"
                gap="10px"
                backgroundColor="#fff"
              >
                <img src={Search} alt="" />
                <Text as="strong" w="100%" fontSize="13px">
                  Pesquisar hospital :
                </Text>
                <Input
                  height="30px"
                  fontSize="13px"
                  padding="0"
                  _focus={{
                    border: "none",
                  }}
                  border="none"
                  placeholder="Digite aqui"
                  _placeholder={{ fontSize: "13px" }}
                />
              </Flex> */}
              {/* <Flex w={{ base: "100%", md: "60%" }} h="30px">
                <PrimaryResultsButton
                  onClick={() => navigate("/gerenciamento-grupos/nova-equipe")}
                >
                  <Flex justifyContent="space-between" gap="10px">
                    <img src={iconAdd} alt="" />

                    <Text w="100%" fontSize="12px">
                      Adicionar nova equipe
                    </Text>
                  </Flex>
                </PrimaryResultsButton>
              </Flex> */}
            </Flex>

            <Flex
              w="100%"
              justifyContent="space-around"
              mt="20px"
              pt="23px"
              // borderTop="1px solid var(--orange-senne)"
              // gap="63.3px"
              gap="30.3px"
              wrap="wrap"
            >
              {/* uma quadro de grupo */}
              {groupList?.map((item, index) => (
                <Flex
                  key={index}
                  pt="10px"
                  flexDirection="column"
                  alignContent="center"
                  alignItems="center"
                  gap="5px"
                  h="300px"
                  border="1px solid var(--cinza-card)"
                  borderRadius="9px"
                  wrap="wrap"
                  mb="17px"
                  p="17px"
                  justifyContent="space-around"
                >
                  <Flex
                    w="78px"
                    h="78px"
                    borderRadius="100%"
                    overflow="hidden"
                    justifyContent="center"
                    alignItems="center"
                    border="1px solid var(--cinza-card)"
                  >
                    <img src={item?.image ?? avatarGroup} alt="" />
                  </Flex>

                  <Text maxW="150px" textAlign="center">
                    {item?.name}
                  </Text>
                  <Box w="24px" h="24px">
                    <img src={avatarGroup} alt="" />
                  </Box>
                  <Box w="150px" h="24px">
                    <PrimaryResultsButton
                      onClick={() =>
                        navigate(
                          `/gerenciamento-senne/gerenciamento-grupos/membros/${item?.id}`,
                        )
                      }
                    >
                      <Text fontSize="10px"> Ver membros</Text>
                    </PrimaryResultsButton>
                  </Box>
                  <Box w="105px" h="16px">
                    <CancelButton
                      onClick={() =>
                        navigate(
                          `/gerenciamento-senne/gerenciamento-grupos/${groupId}/${item?.id}`,
                        )
                      }
                    >
                      <Text fontSize="10px">
                        {groupId ? "Editar Hospital" : "Editar Grupo"}
                      </Text>
                    </CancelButton>
                  </Box>
                </Flex>
              ))}
            </Flex>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default HospitalManager;
