import React from "react";
import api from "../services/api";
import { toast } from "react-toastify";

export const GroupsContext = React.createContext({});

export const GroupsProvider = ({ children }) => {
  async function getGroups(params) {
    const response = await api.get("list/procedencia/groups", { params });
    return response.data;
  }

  async function getGroupById(groupId) {
    const response = await api.get("list/procedencia/groups");
    return response.data.Grupos.filter(
      (group) => group.id === parseInt(groupId),
    );
  }

  async function editGroup(group_id, data) {
    try {
      const response = await api.put(`edit/group/${group_id}`, data);
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      return error;
    }
  }
  const editGroupImage = async (data) => {
    const response = await api.post(`/upload/group/image`, data);
    toast.success("Imagem Editada com sucesso.");

    return response;
  };
  return (
    <GroupsContext.Provider
      value={{ getGroups, editGroupImage, editGroup, getGroupById }}
    >
      {children}
    </GroupsContext.Provider>
  );
};
