import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./services/Router";
import GlobalStyle from "./styles/global";
import { AppProvider } from "./contexts";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  components: {
    Progress: {
      variants: {
        greenSenne: {
          bg: "#b9bd5c",
        },
        // cinzaCard: "#D7D7D7",
        // blueSenne: "#0DCAF0",
        // cinzaBg: "#F7F8FC",
        // orangeHover: "#FDEFE2",
        // orangeSenne: "#F3953F",
        // grayDark: "#343A40",
        // black50: "#00000080",
        // orangeSenneSecundary: "#EC6726",
      },
    },
  },
});

// const theme = extendTheme({
//   colors: {
//     brand: {
//       100: "#f7fafc",
//       // ...
//       900: "#1a202c",
//     },
//   },
// });

// 3. Pass the new theme to `ChakraProvider`

// 4. Now you can use these colors in your components

function App() {
  return (
    <>
      <ChakraProvider theme={theme}>
        <GlobalStyle />
        <AppProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </AppProvider>
      </ChakraProvider>
    </>
  );
}

export default App;
