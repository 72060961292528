import React from "react";

// import { Container } from './styles';

const ExamSidebarIcon: React.FC = () => {
  return (
    <>
      <svg
        width="34"
        height="36"
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.25 15.125C4.25 14.7603 4.38828 14.4106 4.63442 14.1527C4.88056 13.8949 5.2144 13.75 5.5625 13.75C5.9106 13.75 6.24444 13.8949 6.49058 14.1527C6.73672 14.4106 6.875 14.7603 6.875 15.125V16.5C6.875 16.8647 6.73672 17.2144 6.49058 17.4723C6.24444 17.7301 5.9106 17.875 5.5625 17.875C5.2144 17.875 4.88056 17.7301 4.63442 17.4723C4.38828 17.2144 4.25 16.8647 4.25 16.5V15.125ZM12.125 9.625C12.125 9.26033 12.2633 8.91059 12.5094 8.65273C12.7556 8.39487 13.0894 8.25 13.4375 8.25C13.7856 8.25 14.1194 8.39487 14.3656 8.65273C14.6117 8.91059 14.75 9.26033 14.75 9.625V16.5C14.75 16.8647 14.6117 17.2144 14.3656 17.4723C14.1194 17.7301 13.7856 17.875 13.4375 17.875C13.0894 17.875 12.7556 17.7301 12.5094 17.4723C12.2633 17.2144 12.125 16.8647 12.125 16.5V9.625ZM8.1875 12.375C8.1875 12.0103 8.32578 11.6606 8.57192 11.4027C8.81806 11.1449 9.1519 11 9.5 11C9.8481 11 10.1819 11.1449 10.4281 11.4027C10.6742 11.6606 10.8125 12.0103 10.8125 12.375V16.5C10.8125 16.8647 10.6742 17.2144 10.4281 17.4723C10.1819 17.7301 9.8481 17.875 9.5 17.875C9.1519 17.875 8.81806 17.7301 8.57192 17.4723C8.32578 17.2144 8.1875 16.8647 8.1875 16.5V12.375Z"
          className="icon"

          
        />
        <path
          d="M4.25 2.0625H2.9375C2.24131 2.0625 1.57363 2.35223 1.08134 2.86796C0.589062 3.38368 0.3125 4.08315 0.3125 4.8125V19.25C0.3125 19.9793 0.589062 20.6788 1.08134 21.1945C1.57363 21.7103 2.24131 22 2.9375 22H16.0625C16.7587 22 17.4264 21.7103 17.9187 21.1945C18.4109 20.6788 18.6875 19.9793 18.6875 19.25V4.8125C18.6875 4.08315 18.4109 3.38368 17.9187 2.86796C17.4264 2.35223 16.7587 2.0625 16.0625 2.0625H14.75V3.4375H16.0625C16.4106 3.4375 16.7444 3.58237 16.9906 3.84023C17.2367 4.09809 17.375 4.44783 17.375 4.8125V19.25C17.375 19.6147 17.2367 19.9644 16.9906 20.2223C16.7444 20.4801 16.4106 20.625 16.0625 20.625H2.9375C2.5894 20.625 2.25556 20.4801 2.00942 20.2223C1.76328 19.9644 1.625 19.6147 1.625 19.25V4.8125C1.625 4.44783 1.76328 4.09809 2.00942 3.84023C2.25556 3.58237 2.5894 3.4375 2.9375 3.4375H4.25V2.0625Z"
          className="icon"

        />
        <path
          d="M11.4688 1.375C11.6428 1.375 11.8097 1.44743 11.9328 1.57636C12.0559 1.7053 12.125 1.88016 12.125 2.0625V3.4375C12.125 3.61984 12.0559 3.7947 11.9328 3.92364C11.8097 4.05257 11.6428 4.125 11.4688 4.125H7.53125C7.3572 4.125 7.19028 4.05257 7.06721 3.92364C6.94414 3.7947 6.875 3.61984 6.875 3.4375V2.0625C6.875 1.88016 6.94414 1.7053 7.06721 1.57636C7.19028 1.44743 7.3572 1.375 7.53125 1.375H11.4688ZM7.53125 0C7.00911 0 6.50835 0.217298 6.13913 0.604092C5.76992 0.990886 5.5625 1.51549 5.5625 2.0625V3.4375C5.5625 3.98451 5.76992 4.50911 6.13913 4.89591C6.50835 5.2827 7.00911 5.5 7.53125 5.5H11.4688C11.9909 5.5 12.4917 5.2827 12.8609 4.89591C13.2301 4.50911 13.4375 3.98451 13.4375 3.4375V2.0625C13.4375 1.51549 13.2301 0.990886 12.8609 0.604092C12.4917 0.217298 11.9909 0 11.4688 0L7.53125 0Z"
          className="icon"

        />
      </svg>
    </>
  );
};

export default ExamSidebarIcon;
