import React, { useState, useEffect } from "react";
import { registerLocale } from "react-datepicker";
import {
  Flex,
  Box,
  extendTheme,
  useMediaQuery,

} from "@chakra-ui/react";

// import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'

import GridAttendance from "../../components/GridAttendance";
import ptBR from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

import SideBar from "../../components/SideBar";
import InternalHeader from "../../components/InternalHeader";
import { useAuth } from "../../hooks";

const breakpoints = {
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

extendTheme({ breakpoints });

function Exams() {
  // const navigate = useNavigate();
  registerLocale("ptBR", ptBR);

  const [isMobile] = useMediaQuery("(max-width: 820px)") 

  const { user } = useAuth();

  // const [search, setSearch] = useState("");
  const [showHospital, setShowHospital] = useState(false);

  const itemsPerPage = 7;
  // const [pageCount, setPageCount] = useState(0);
  // const [currentPage, setCurrentPage] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [widthScreen, setWidthScreen] = useState(0);

  // var today = new Date();

  const [uuidUser, setUUIDUser] = useState();

  const handleOnChangeHospital = (uuidChanged) => {
    setUUIDUser(uuidChanged);
  };

  useEffect(() => {
    if(!uuidUser && user.role_id === 4){
      setUUIDUser(user.hash);
      return;
    }

    if(!uuidUser && user.role_id !== 3 && user.hospitals.length > 0){
      setUUIDUser(user.hospitals[0].uuid );
    } 

    if(user.role_id === 1 || user.hospitals.length > 0){
      // setUUIDUser(user.hospitals[0].uuid);
      setShowHospital(true);
    }

    
  }, []);


 
  return (
    <Flex backgroundColor="#F7F8FC" minH="100vh" minW="100vw">

      {
        !isMobile && (

          <Flex width="220px" id="sidebar" className="sidebar">
            <SideBar />
          </Flex>

        )
      }
   
      <Box 
        p={ isMobile ? "3em 1em 3em 1em" : "3em 3em 3em 3em" }
      
        w="100%"
      >
        
        <InternalHeader 
          onChangeHospital={handleOnChangeHospital} 
          setUUIDUser={setUUIDUser} 
          uuidUser={uuidUser} 
          showHospital={showHospital}
          isMobile={isMobile}
        />
        <Flex  mb="12">
          
        </Flex>
        
        <GridAttendance 
          itemsPage={itemsPerPage}
          uuidSelected={uuidUser}
        />

      </Box>
    </Flex>
  );
}

export default Exams;
