import React, { useState, useEffect } from "react";
import { useAuth,useHospitals } from "../../hooks";


import { Flex, Text } from "@chakra-ui/react";

import Cliente from "../../images/clienteSignin.svg";

import TextSelectHospital from "../TextSelectHospital";

import LogoIcon from "../../assets/icons/LogoIcon.tsx";


const InternalHeader = ({
  onChangeHospital,
  uuidUser,
  setUUIDUser,
  showHospital,
  isMobile,

}) => {
  const { user } = useAuth();

  const [uuidHopistal, setUuidHospital] = useState(false);

  const [hospitalListLen, setHospitalListLen] = useState(false);

  const { getHospitalsList, getUserHospitalList } = useHospitals();

  // const [uuidUserChange, setUUIDUser] = useState();

  const [hospitalsListNoPagination, setHospitalsListNoPagination] = useState(
    [],
  );  

  async function getHospitalList() {
    try {
      const response = await getHospitalsList();

      setHospitalListLen(response.length);

      setHospitalsListNoPagination(response);
    } catch (error) {}
  }

  async function getUserHospitalsList() {
    try {
      const response = await getUserHospitalList();
      setHospitalsListNoPagination(response);

      setHospitalListLen(response.length);      

    } catch (error) {
      console.error(error);
    }
  }  


  // eslint-disable-next-line no-redeclare
  async function onChangeHospital() {
    return uuidUser;
  }

  function getDate() {
    if (user?.role_id === 1) {
      setUUIDUser(uuidHopistal);
    }
    if (user?.role_id === 3) {
      setUUIDUser(user?.hash);
    }
    if (user?.role_id === 4) {
      setUUIDUser(user?.uuid_doc);
    }
  }


  const list = [
    {
      role_id: 1,
      text: "Administrador Senne",
    },
    {
      role_id: 2,
      text: user?.hospitals?.[0]?.name,
    },
    {
      role_id: 3,
      text: "Paciente",
    },
    {
      role_id: 4,
      text: "Médico",
    },
    {
      role_id: 5,
      text: "Atendimento",
    },
  ];


  useEffect(() => {
    if (
      user?.cod_pf ||
      user?.hash ||
      user?.hospitals?.[0]?.uuid ||
      uuidHopistal ||
      user?.uuid_doc
    ) {
      getDate();
    }

    if (user?.role_id === 1) {
      getHospitalList();
    }

    if (user?.role_id === 2) {
      getUserHospitalsList();

    
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  

  useEffect(() =>{
    onChangeHospital();

  },[uuidUser])


    

  return (

    <Flex
      alignContent="center"
      alignItems="center"
      gap={ !isMobile && "70px"}
      w="100%"
      mr="10px"
      flexDirection={{ base: "column", md: "row" }}
    >
      <Flex>

        {

          isMobile && (
            <Flex paddingRight="2em">
              <LogoIcon />
            </Flex>
        
          )
        }

        <img src={Cliente} alt="logo" />
        <Flex flexDirection="column" ml="16px" w="100%">
          {list?.map((item, index) => {
            if (user?.role_id === item?.role_id) {
              return (
                <Text key={index} fontSize="13px" as="strong">
                  {item.text}
                </Text>
              );
            }
          })}
          <Text as="strong" fontSize="13px" w="100%">
            {user?.name}
          </Text>
        </Flex>
      </Flex>
      <Flex>
      { 
          hospitalListLen > 1 && (user.role_id === 1 || user.role_id === 2) && showHospital ? (
          <Flex>
            <TextSelectHospital
              labelDescription="Hospital"
              name="uf"
              id="uf"
              titleOption="Escolha o hospital"
              onChange={(e) => setUUIDUser(e.target.value)}
              options={hospitalsListNoPagination}
            />
          </Flex>
        ) : null}

      </Flex>
    </Flex>
  );
};

export default InternalHeader;
