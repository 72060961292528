import axios from "axios";

const api_public = axios.create({
  
  //baseURL: "https://cloud-api.senneliquor.com.br/api", // Servidor Teste
   //baseURL: "http://localhost:8000/api", // Local
  //baseURL: "https://api-laravel.proudground-c683b123.brazilsouth.azurecontainerapps.io",
  //baseURL: "https://api-hom.senneliquor.com.br/api",
  // baseURL: "http://SENNEDESK04001:8000/api", // Local
  baseURL: "https://api-prod.senneliquor.com.br/api",
  //baseURL: process.env.baseURL,
  timeout: 1000*30,
});

export default api_public;
