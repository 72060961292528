import React from "react";

const HouseIcon: React.FC = () => {
  return (

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="55"
      fill="none"
      viewBox="0 0 53 55"
    >
      <path
        className="icon"
        d="M2.21 23.75a2.47 2.47 0 01.59-.827L24.88 2.64l.006-.005A2.374 2.374 0 0126.498 2c.594 0 1.17.224 1.613.635l.005.005L50.2 22.923c.25.23.451.512.59.827.138.316.21.658.21 1.005v25.783a2.502 2.502 0 01-.715 1.748 2.38 2.38 0 01-1.702.714h0l-13.251-.002h0c-.049 0-.1-.02-.14-.06a.242.242 0 01-.069-.171V32.919h-17.25v19.848a.243.243 0 01-.068.17.197.197 0 01-.14.061h0L4.417 53a2.38 2.38 0 01-.918-.184l-.769 1.837.769-1.838a2.412 2.412 0 01-.784-.529l-1.421 1.407 1.421-1.407A2.502 2.502 0 012 50.538V24.754c0-.347.072-.689.21-1.004z"
      ></path>
    </svg>
  );
}

export default HouseIcon;
