import { Flex, Text, Img } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";

import { GoBackButton, RegisterButton } from "../../../components/Buttons";
import TemplateRegister from "../../../components/TemplateRegister";
import TextInput from "../../../components/TextInput";

import Labor from "../../../images/LabCar.svg";

const schema = yup.object().shape({
  name: yup.string().required("O campo nome é obrigatório"),
  email: yup.string().email().required("O campo email é obrigatório"),
  cpf: yup.string().required("O campo CPF é obrigatório"),
  phone: yup.string().required("O campo telefone é obrigatório"),
});

export default function LaboratoryStepOne({ props, setProps, setSteps }) {
  async function submit(values) {
    const valuesOne = { ...props, ...values };
    setProps(valuesOne);
    setSteps(1);
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  return (
    <TemplateRegister>
      <Content>
        <Flex
          flexDirection="column"
          backgroundColor="white"
          p="26px 46px"
          borderRadius="8px"
          w="100%"
          minW="535px"
        >
          <form onSubmit={handleSubmit(submit)}>
            <Flex
              flexDirection="column"
              alignContent="center"
              alignItems="center"
              mb="42px"
              gap="10px"
            >
              <Flex w="100%" justifyContent="center" gap="1.5em">
                <Img w="42px" src={Labor} alt="" />
                <Text
                  w="150px"
                  as="strong"
                  color="var(--orange-senne-secundary)"
                >
                  Demais
                  <br /> Parceiros
                </Text>
              </Flex>
              <h1 fontSize="40px">Faça seu Cadastro</h1>
            </Flex>

            <Flex
              w="100%"
              gap="10px"
              justifyContent="center"
              flexDirection="column"
            >
              <TextInput
                isError={errors.name}
                register={{ ...register("name") }}
                labelDescription="Seu nome completo"
                InputPlaceholder="Digite seu nome"
                name="name"
                id="name"
              />
              <TextInput
                isError={errors.email}
                register={{ ...register("email") }}
                labelDescription="Digite seu email"
                InputPlaceholder="Digite seu email"
                name="email"
                id="email"
              />
              <TextInput
                isError={errors.cpf}
                register={{ ...register("cpf") }}
                labelDescription="Digite seu CPF"
                InputPlaceholder="Digite seu CPF"
                maskInput="***.***.***-**"
                name="cpf"
                id="cpf"
              />{" "}
              <TextInput
                isError={errors.phone}
                register={{ ...register("phone") }}
                labelDescription="Qual seu telefone"
                InputPlaceholder="Qual seu telefone"
                maskInput="(99) 9999-9999"
                name="phone"
                id="phone"
              />
            </Flex>

            <Flex mt="30px" justifyContent="space-between" alignItems="center">
              <Link to="/cadastro">
                <GoBackButton />
              </Link>

              <RegisterButton type="submit">Continuar</RegisterButton>
            </Flex>
          </form>
        </Flex>
      </Content>
    </TemplateRegister>
  );
}
const Content = styled.div`
  padding: 105px 0;
  color: var(--gray-dark);
  h1 {
    margin: 10px 0;
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    line-height: 36px;
    font-weight: bold;
    font-weight: 700;
  }
  h2 {
    font-size: 1rem;
    font-weight: 600;
  }
  p {
    line-height: 24px;
    font-size: 16px;
    margin: 16px 0;
  }
  > div {
  }
  a {
    text-decoration: none;
  }
`;
