import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
  }
  *:focus{
    outline: 0;
  }

  html, body, #root{
    width: 100%;
    height: 100%;
    min-height:100%;
    text-rendering: optimizelegibility;
    background-color: ${({ theme }) => theme.colors.background};
  }
  body{
    -webkit-font-smoothing: antialiased;
  }

  a{
    text-decoration: none;
  }
  ul{
    list-style: none;
  }
  button{
    cursor: pointer;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ff6f61;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ff6f61;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #ff6f61;
  }
  ::-webkit-scrollbar-track {
    background: #fafbfb;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: #fafbfb;
  }
  ::-webkit-scrollbar-track:active {
    background: #f8e1e1;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export default GlobalStyles;
