import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useUsers, useAuth, useGroups } from "../../hooks";
import SideBar from "../../components/SideBar";
import { Pagination } from "../../components/Pagination";
import { FormatFulldate } from "../../components/Format";

import {
  Flex,
  Box,
  Text,
  Input,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import InternalHeader from "../../components/InternalHeader";
import Breadcrumb from "../../components/Breadcrumb";
import {
  GreenButton,
  PrimaryResultsButton,
} from "../../components/Buttons/index";

import Loading from "../../components/Loading";

import Add from "../../images/add.svg";
import { BreadCrumbs } from "../../components/Breadcrumb2";

function MembersGroup() {
  const { id_Hospital: hospital_id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();
  const [usersToShow, setUsersToShow] = useState(null);
  const [hospitalName, setHospitalName] = useState(null);
  const { inactiveUser, approveUser, getUsersByHospital } = useUsers();
  const [loading, setLoading] = useState(false);

  const itemsPerPage = 5;
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [uuidUser, setUUIDUser] = useState();
  
  async function handleOnChangeHospital(uuidChanged) {
    setUUIDUser(uuidChanged);
  };  

  useEffect(() => {
    if (hospital_id) {
      getUsersHospital();
    }
  }, [hospital_id]);

  async function getUsersHospital() {
    const params = {
      per_page: itemsPerPage,
      page: currentPage,
      name: "",
    };
    try {
      const result = await getUsersByHospital(hospital_id, params);
      setPageCount(result?.data?.hospital?.users?.last_page);
      setUsersToShow(result?.data?.hospital?.users?.data);
      setHospitalName(result?.data?.hospital?.name);
    } catch (error) {
      console.log(error);
    }
  }

  async function AproveUsers(id) {
    try {
      setLoading(true);

      const data = {
        id: id,
      };
      const response = await approveUser(id, data);
      getUsersHospital();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function inactive_user(id, status) {
    try {
      const data = { status: status };
      setLoading(true);

      const response = await inactiveUser(id, data);
      getUsersHospital();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Flex width="100%" maxHeight="100vh" backgroundColor="#F7F8FC">
      {loading && <Loading />}
      <Flex width="220px">
        <SideBar />
      </Flex>
      <Box p="24px 88px 22px 24px" w="calc(100% - 200px)">
        <Flex alignContent="center" justifyContent="space-between" w="100%">
          <InternalHeader  setHospital={handleOnChangeHospital} setUUIDUser={setUUIDUser} uuidUser={uuidUser} />
        </Flex>

        <BreadCrumbs title={"Membros do " + hospitalName} />

        <Box
          mt="44px"
          p="15px 20px"
          border="1px solid #CED4DA"
          borderRadius="10px"
          backgroundColor="#fff"
        >
          <Flex justifyContent="space-between">
            {/* <Flex
              w="477px"
              p="0 12px"
              border="1px solid #CED4DA"
              color="#8F8F8F"
              borderRadius="4px"
              alignContent="center"
              alignItems="center"
              gap="10px"
            >
              <img src={Search} alt="" />
              <Text as="strong">Pesquisar:</Text>
              <Input
                padding="0"
                _focus={{
                  border: "none",
                }}
                border="none"
                placeholder="Digite Aqui"
                onChange={handleFilter}
              />
            </Flex> */}
            <Flex
              color="#8F8F8F"
              placeholder="Pesquisar:"
              alignContent="center"
              alignItems="center"
              gap="24px"
            >
              {/* <Link to="/users/add">
                <CancelButton>
                  <img src={Remove} alt="" />
                  <Text pl="30px">Inativar Usuário</Text>
                </CancelButton>
              </Link> */}
              <Link to={`/users/add/${hospital_id}`}>
                <PrimaryResultsButton>
                  <img src={Add} alt="" />
                  <Text pl="30px">Adicionar membro</Text>
                </PrimaryResultsButton>
              </Link>
            </Flex>
          </Flex>

          <Flex
            mt="20px"
            pt="20px"
            borderTop="1px solid var(--orange-senne)"
            fontSize="13px"
            lineHeight="14px"
          >
            <TableContainer
              w="100%"
              borderRadius="8px"
              border="var(--cinza-card) 1px solid"
              // height="380px"
              maxH="300px"
              overflowY="scroll"
            >
              <Table variant="simple">
                <Thead backgroundColor="var(--cinza-bg)">
                  <Tr>
                    {/* <Th></Th> */}
                    <Th borderLeft="var(--cinza-card) 1px solid">
                      Nome do usuário
                    </Th>
                    <Th borderLeft="var(--cinza-card) 1px solid">Unidade</Th>
                    <Th borderLeft="var(--cinza-card) 1px solid">Status</Th>
                    <Th borderLeft="var(--cinza-card) 1px solid">Email</Th>
                    <Th borderLeft="var(--cinza-card) 1px solid">
                      Último Login
                    </Th>
                    <Th borderLeft="var(--cinza-card) 1px solid">Ações</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {usersToShow?.map((user, index) => (
                    <Tr
                      key={index}
                      _hover={{
                        backgroundColor: "var(--orange-hover)",
                        // cursor: "pointer",
                      }}
                      // onClick={() => navigate(`/users/${user?.id}`)}
                    >
                      {/* <Td p="0 8px">
                        <Flex w="100%" justifyContent="center">
                          <Checkbox />
                        </Flex>
                      </Td> */}
                      <Td p="0 8px" borderLeft="1px solid var(--cinza-card)">
                        {user?.name}
                      </Td>
                      <Td
                        overflowY
                        maxH="15px"
                        p="0 8px"
                        borderLeft="1px solid var(--cinza-card)"
                      >
                        {hospitalName}
                      </Td>
                      <Td
                        p="0 8px"
                        alignContent="center"
                        alignItems="center"
                        borderLeft="1px solid var(--cinza-card)"
                      >
                        <Button
                          _hover={{ opacity: 0.7 }}
                          fontSize="12px"
                          h="30px"
                          borderRadius="20px"
                          backgroundColor={
                            user?.status === 0
                              ? "var(--black-50)"
                              : user?.status === 1
                              ? "var(--blue-senne)"
                              : "var(--orange-senne)"
                          }
                          color="white"
                        >
                          {user?.status === 0
                            ? "Inativo"
                            : user?.status === 1
                            ? "ativo"
                            : user?.status === 2
                            ? "pendente de Ativação"
                            : "Pendente de Aprovação"}
                        </Button>
                      </Td>
                      <Td p="0 8px" borderLeft="1px solid var(--cinza-card)">
                        {user?.email}
                      </Td>
                      <Td p="15px 8px" borderLeft="1px solid var(--cinza-card)">
                        {user?.dateLogin?.created_at
                          ? FormatFulldate(user?.dateLogin?.created_at)
                          : "Nunca Logou"}
                      </Td>

                      <Td p="0 8px" borderLeft="1px solid var(--cinza-card)">
                        <Menu>
                          <MenuButton
                            style={{
                              backgroundColor: "transparent",
                              display: "flex",
                              margin: "0 auto",
                            }}
                            as={IconButton}
                            icon={
                              <GreenButton
                                style={{
                                  padding: "0 8px 8px",
                                  width: "40px",
                                }}
                              >
                                ...
                              </GreenButton>
                            }
                          />
                          <MenuList>
                            <Link
                              fontSize="12px"
                              to={`/users/${user?.id}` || navigate("/users")}
                            >
                              <MenuItem fontSize="12px">
                                Editar cadastro
                              </MenuItem>
                            </Link>
                            {(user?.status === 0 || user?.status === 1) && (
                              <Link
                                fontSize="12px"
                                to={""}
                                onClick={() =>
                                  user?.status === 0 || 1
                                    ? inactive_user(
                                        user?.id,
                                        user?.status === 0 ? 1 : 0,
                                      )
                                    : ""
                                }
                              >
                                <MenuItem fontSize="12px">
                                  {user?.status === 1
                                    ? "Inativar Usuário"
                                    : user?.status === 0
                                    ? "Ativar Usuário"
                                    : ""}
                                </MenuItem>
                              </Link>
                            )}
                            <MenuItem fontSize="12px">Reenviar email</MenuItem>

                            {user?.status === 3 && (
                              <Link
                                fontSize="12px"
                                to={""}
                                onClick={() => AproveUsers(user?.id)}
                              >
                                <MenuItem fontSize="12px">
                                  <Text>Aprovar Usuário</Text>
                                </MenuItem>
                              </Link>
                            )}
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
          <Flex mt="18px" justifyContent="end">
            <Pagination
              pageCount={pageCount}
              changePage={setCurrentPage}
              pageSelected={currentPage}
            />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
}

export default MembersGroup;
