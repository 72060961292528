import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Flex,
  Text,
  Img,
  Radio,
  RadioGroup,
  Stack,
  Checkbox,
  CheckboxGroup,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";

import { GoBackButton, RegisterButton } from "../../../components/Buttons";
import TemplateRegister from "../../../components/TemplateRegister";
import TextInput from "../../../components/TextInput";
import Loading from "../../../components/Loading";
import Terms from "../../../components/Term.jsx";

import { useRegister } from "../../../hooks";

import AgrePolicy from "../../../images/Política de Privacidade_PortalSenne.pdf";
import AgreTerms from "../../../images/Termo de Uso_Portal Senne.pdf";
import Hospital from "../../../images/labOrange.svg";

const schema = yup.object().shape({
  cellphone: yup.string().when("typePhone", {
    is: "1",
    then: yup.string().trim().required("O campo telefone é obrigatório!"),
  }),
  phone: yup.string().when("typePhone", {
    is: "2",
    then: yup.string().trim().required("O campo telefone é obrigatório!"),
  }),
  ramal: yup.string().trim(),
  email: yup
    .string()
    .email("Email inválido")
    .required("O campo Email é obrigatório"),
  department: yup.string().required("O campo departamento obrigatório"),
  policy: yup
    .boolean()
    .required("leia e aceite os termos antes de prosseguir.")
    .oneOf([true], "Leia e aceite os termos antes de prosseguir."),
  termsOfNotice: yup.boolean(),
  termsOfContact: yup.boolean(),
});
export default function RegisterhospitalStepTwo(props) {
  const navigate = useNavigate();
  const { createUserHospital } = useRegister();

  const [setor, setSetor] = useState("1");
  const [isErrorMsg, setIsErrorMsg] = useState(false);
  const [loading, setLoading] = useState(false);

  async function submit(values) {
    try {
      setLoading(true);

      delete values.typePhone;

      if (!values.results && !values.scheduling) {
        toast.warning("selecione uma permissão");
        setIsErrorMsg(true);
        return;
      }
      const permissions = [];
      if (values.scheduling) {
        permissions.push(2);
      }
      if (values.results) {
        permissions.push(3);
      }

      const valuesAPI = {
        ...values,
        ...props.props,
        permissions: permissions,
        department: Number(values.department),
      };

      const response = await createUserHospital(valuesAPI);

      if (response.status === 200) {
        navigate("/cadastro-solicitado");
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  function openpdf(item) {
    window.open(item, "_blank");
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  return (
    <>
      <TemplateRegister>
        {loading && <Loading />}
        <Content>
          <Flex
            flexDirection="column"
            backgroundColor="white"
            p="20px 46px"
            borderRadius="8px"
            w="100%"
          >
            <form onSubmit={handleSubmit(submit)}>
              <Flex
                flexDirection="column"
                alignContent="center"
                alignItems="center"
                mb="25px"
                gap="10px"
              >
                <Flex
                  w="100%"
                  justifyContent="center"
                  gap="1.5em"
                  alignContent="center"
                  alignItems="center"
                >
                  <Img w="42px" src={Hospital} alt="" />
                  <Text
                    w="150px"
                    as="strong"
                    color="var(--orange-senne-secundary)"
                  >
                    Hospital
                  </Text>
                </Flex>
                <h1 fontSize="40px">Faça seu Cadastro</h1>
              </Flex>
              {/* <RadioGroup defaultValue="1">
                <Stack
                  w="100%"
                  spacing={10}
                  direction="row"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                >
                  <Radio colorScheme="red" value="1" {...register("typePhone")}>
                    Telefone Celular
                  </Radio>
                  <Radio
                    colorScheme="green"
                    value="2"
                    {...register("typePhone")}
                  >
                    Telefone Fixo
                  </Radio>
                </Stack>
              </RadioGroup> */}
              {/* {PhoneNumber === "1" ? ( */}
                <TextInput
                  isError={errors.cellphone}
                  register={{ ...register("cellphone") }}
                  labelDescription="Qual é o seu Celular"
                  InputPlaceholder="Digite seu Celular"
                  maskInput="(99) 9 9999-9999"
                  name="cellphone"
                  id="cellphone"
                />
              {/* ) : (
                <Flex gap="5%">
                  <Flex w="65%">
                    <TextInput
                      isError={errors.phone}
                      register={{ ...register("phone") }}
                      labelDescription="Qual seu telefone"
                      InputPlaceholder="Digite seu telefone"
                      maskInput="(99) 9999-9999"
                      name="phone"
                      id="phone"
                    />
                  </Flex>
                  <Flex w="30%">
                    <TextInput
                      isError={errors.ramal}
                      register={{ ...register("ramal") }}
                      labelDescription="Ramal"
                      InputPlaceholder="1234"
                      maskInput="9999"
                      name="ramal"
                      id="ramal"
                    />
                  </Flex>
                </Flex>
              )} */}
              <TextInput
                isError={errors.email}
                register={{ ...register("email") }}
                labelDescription="Email"
                InputPlaceholder="Digite o email"
                name="email"
                id="email"
              />
              <Flex mt="20px">
                <Text as="strong">Departamento/Setor</Text>
              </Flex>

              <RadioGroup onChange={setSetor} value={setor} colorScheme="red">
                <Stack direction="row" gap="20px">
                  <Radio value="1" {...register("department")}>
                    Corpo Clínico
                  </Radio>
                  <Radio value="2" {...register("department")}>
                    Enfermagem
                  </Radio>
                  <Radio value="3" {...register("department")}>
                    Administrativo
                  </Radio>
                </Stack>
              </RadioGroup>
              <Flex mt="20px">
                <Text as="strong">Solicitar Acessos</Text>
              </Flex>
              <CheckboxGroup defaultValue={["scheduling", "results"]}>
                <Stack spacing={[1, 5]} direction={["column", "row"]}>
                  <Checkbox
                    {...register("results")}
                    defaultChecked={true}
                    value=""
                  >
                    Resultados
                  </Checkbox>
                  <Checkbox
                    {...register("scheduling")}
                    defaultChecked={true}
                    value=""
                  >
                    Agendamentos
                  </Checkbox>
                </Stack>
                {isErrorMsg && (
                  <Text color="red" as="strong">
                    Selecione uma permissão
                  </Text>
                )}
              </CheckboxGroup>

              <Terms
                register={{ ...register("policy") }}
                isError={errors.policy}
              >
                <Text p="0">
                  Li e aceito a
                  <Link to="" onClick={() => openpdf(AgrePolicy)}>
                    <Text as="strong"> Política de Privacidade </Text>
                  </Link>
                  e os
                  <Link to="" onClick={() => openpdf(AgreTerms)}>
                    <Text as="strong"> Termos de uso</Text>
                  </Link>
                  .
                </Text>
              </Terms>
              <Flex
                justifyItems="stretch "
                alignContent="center"
                alignItems="center"
                gap="10px"
                w="600px"
              >
                <Terms
                  register={{ ...register("termsOfNotice") }}
                  isError={errors.termsOfNotice}
                >
                  <Text p="0">
                    Deseja receber notícias e informações nos canais de
                    comunicação?
                  </Text>
                </Terms>
              </Flex>
              <Flex
                justifyItems="stretch "
                alignContent="center"
                alignItems="center"
                gap="10px"
                w="600px"
              >
                <Terms
                  register={{ ...register("termsOfContact") }}
                  isError={errors.termsOfContact}
                >
                  <Text p="0">
                    Você permite que a nossa equipe entre em contato para a
                    discussão de caso e informe de resultados alterados?
                  </Text>
                </Terms>
              </Flex>

              <Flex
                mt="50px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Link to="/cadastro">
                  <GoBackButton />
                </Link>

                <RegisterButton type="submit">Finalizar</RegisterButton>
              </Flex>
            </form>
          </Flex>
        </Content>
      </TemplateRegister>
    </>
  );
}
const Content = styled.div`
  padding: 105px 0;
  color: var(--gray-dark);
  h1 {
    margin: 10px 0;
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    line-height: 36px;
    font-weight: bold;
    font-weight: 700;
  }
  h2 {
    font-size: 1rem;
    font-weight: 600;
  }
  p {
    line-height: 24px;
    font-size: 16px;
    margin: 16px 0;
  }
  > div {
  }
  a {
    text-decoration: none;
  }
`;
