import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AuthProvider } from "./Auth";
import { UsersProvider } from "./Users";
import { ExamsProvider } from "./Exams";
import { HospitalsProvider } from "./Hospitals";
import { GroupsProvider } from "./Groups";
import { RegisterProvider } from "./Register";
import { PartnersProvider } from "./Partners";

export const AppProvider = ({ children }) => {
  return (
    <RegisterProvider>
      <AuthProvider>
        <UsersProvider>
          <HospitalsProvider>
            <PartnersProvider>
              <ExamsProvider>
                <GroupsProvider>{children}</GroupsProvider>
                <ToastContainer />
              </ExamsProvider>
            </PartnersProvider>
          </HospitalsProvider>
        </UsersProvider>
      </AuthProvider>
    </RegisterProvider>
  );
};
