import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";

import SideBar from "../../components/SideBar";
import { Pagination } from "../../components/Pagination";
import {
  GreenButton,
  CancelButton,
  PrimaryResultsButton,
} from "../../components/Buttons";
import InternalHeader from "../../components/InternalHeader";
import TextInput from "../../components/TextInput";
import { BreadCrumbs } from "../../components/Breadcrumb2";

import addNewFoto from "../../images/addNewfoto.svg";

import { useHospitals, useUsers, useGroups } from "../../hooks";

import {
  Flex,
  Box,
  Text,
  UnorderedList,
  ListItem,
  Divider,
  Table,
  Tr,
  Th,
  Td,
  Thead,
  Tbody,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  MenuItem,
  TableContainer,
} from "@chakra-ui/react";

import ProfileImage from "../../images/profile.png";

import Hospital from "../../images/labOrange.svg";

const schema = yup.object().shape({
  cnpj: yup.string(),
  phone: yup.string(),
});

const GroupProfile = () => {
  const navigate = useNavigate();
  const { id: groupId } = useParams();
  const { listHospital } = useHospitals();
  const { getUsersByGroup } = useUsers();
  const { editGroup, editGroupImage } = useGroups();
  const [listUnit, setListUnit] = useState();
  const [listAdmin, setListAdmin] = useState();
  const [photoBase64, setPhotoBase64] = useState();
  const [GroupImage, setGroupImage] = useState(null);

  async function getListUnit() {
    try {
      const response = await listHospital(groupId);
      setListUnit(response[0]);
    } catch (error) {
      return error;
    }
  }

  async function getUserGroup() {
    try {
      const response = await getUsersByGroup(groupId);

      setListAdmin(response);
    } catch (error) {
      return error;
    }
  }

  function getBase64() {
    let document = "";
    let reader = new FileReader();
    reader.readAsDataURL(GroupImage);

    reader.onloadend = function (e) {
      document = reader.result;
      setPhotoBase64(document);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  useEffect(() => {
    if (GroupImage) {
      getBase64();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GroupImage]);

  useEffect(() => {
    getUserGroup();
    getListUnit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const submit = async (data) => {
    const new_values = {
      ...data,
      name: listUnit?.name,
    };
    const formData = new FormData();
    formData.append("image", GroupImage);
    formData.append("id_group", groupId);

    const response = await editGroup(groupId, new_values);
    if (GroupImage) {
      await editGroupImage(formData);
      if (response?.status === 200) {
        navigate(`/gerenciamento-grupo/${groupId}`);
      }
    }
    if (response?.status === 200) {
      navigate(`/gerenciamento-senne/gerenciamento-grupos`);
    }
  };

  return (
    <>
      {listUnit && (
        <Flex width="100%" maxH="100vh" backgroundColor="var(--cinza-bg)">
          <Flex width="220px">
            <SideBar />
          </Flex>
          <Box p="24px 88px 22px 24px" w="calc(100% - 200px)">
            <Flex alignContent="center" justifyContent="space-between" w="100%">
              <InternalHeader width="673px" />
            </Flex>

            <BreadCrumbs />

            <Flex
              mt="24px"
              border="1px solid var(--cinza-card)"
              borderRadius="8px"
              p="46px 50px"
              backgroundColor="white"
              flexDirection="row"
            >
              <Flex
                width="250px"
                alignItems="start"
                justifyContent="space-between"
                flexDirection="column"
                borderRight="1px solid var(--orange-senne)"
                pr="50px"
                mr="30px"
              >
                <CardImage>
                  <img
                    src={photoBase64 ?? listAdmin?.Group?.image ?? ProfileImage}
                    alt=""
                  />
                </CardImage>
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  m="0 0"
                  w="160px"
                  pb="24px"
                >
                  <Text color="var(--orange-senne)"></Text>
                  <Text>{listAdmin?.Group?.name}</Text>
                  <Text as="strong">{listAdmin?.Group?.name}</Text>
                </Flex>
                <PrimaryResultsButton maxH="30px">
                  <img src={addNewFoto} alt="" />
                  <Text pl="30px">Adicionar nova foto</Text>
                  <input
                    type="file"
                    name="profileImage"
                    accept="image/png, image/jpeg"
                    onChange={(event) => setGroupImage(event.target.files[0])}
                    style={{
                      position: "absolute",
                      width: "100%",
                      heigth: "100%",
                      opacity: 0,
                      cursor: "pointer",
                      zIndex: "999",
                    }}
                  />
                </PrimaryResultsButton>

                <Box m="16px auto 0" w="160px">
                  <Text as="strong">Editar Permissões</Text>
                  <Flex
                    flexDirection="column"
                    gap="10px"
                    m="16px -20px 0 -20px"
                    alignItems="center"
                    border="1px solid var(--cinza-card)"
                    borderRadius="8px"
                    p="10px"
                    maxH="300px"
                    overflowY="scroll"
                  >
                    <Flex
                      p="10px"
                      w="100%"
                      alignContent="center"
                      justifyContent="space-between"
                      gap="12px"
                    >
                      <Flex>
                        <img src={Hospital} alt="" />
                      </Flex>
                      <Flex>
                        <Text fontSize="1rem" as="strong">
                          Unidades Vinculadas
                        </Text>
                      </Flex>
                    </Flex>
                    <UnorderedList>
                      {listUnit?.hospitals.map((item, index) => (
                        <ListItem key={index}>
                          <Text as="strong" fontSize="10px">
                            {item?.name}
                          </Text>
                        </ListItem>
                      ))}
                    </UnorderedList>
                  </Flex>
                </Box>
              </Flex>
              <Flex width="calc(100% - 200px)" flexDirection="column" pl="16px">
                <Text fontSize="1.9rem" as="strong">
                  Informações de grupo
                </Text>
                <form onSubmit={handleSubmit(submit)}>
                  <Flex alignItems="center" gap="65px">
                    <TextInput
                      labelDescription="Nome do grupo"
                      isError={errors.name}
                      name="name"
                      defaultValue={listUnit?.name}
                      value={listUnit?.name || null}
                      disabled={listUnit?.name ? true : false}
                      InputPlaceholder={listUnit?.name}
                      register={{ ...register("name") }}
                    />
                    <TextInput
                      labelDescription="Email"
                      register={{ ...register("email") }}
                      defaultValue={listUnit?.email}
                      value={listUnit?.email || "N/A"}
                      disabled
                      InputPlaceholder={listUnit?.email}
                      name="email"
                    />
                  </Flex>
                  <Flex alignItems="center" gap="65px">
                    <TextInput
                      isError={errors.phone}
                      register={{ ...register("phone") }}
                      name="phone"
                      labelDescription="Telefone"
                      maskInput="(**) *****-****"
                      defaultValue={listUnit?.phone}
                      value={listUnit?.phone || "N/A"}
                      disabled
                      InputPlaceholder={listUnit?.phone}
                    />
                    <TextInput
                      isError={errors.cnpj}
                      register={{ ...register("cnpj") }}
                      name="cnpj"
                      labelDescription="CNPJ"
                      maskInput="**.***.***/****-**"
                      defaultValue={listUnit?.cnpj}
                      value={listUnit?.cnpj || "N/A"}
                      disabled
                      InputPlaceholder={listUnit?.cnpj}
                    />
                  </Flex>
                  <Box
                    m="12px 0 25px 0"
                    w="100%"
                    border="1px solid var(--cinza-card)"
                    borderRadius="8px"
                    p="16px 16px 0"
                    overflow
                  >
                    <Flex alignItems="center" mb="13px">
                      <Box w="240px">
                        <Text fontSize="0.8rem" w="100%" as="strong">
                          Lista de Administradores
                        </Text>
                      </Box>
                      <Divider
                        orientation="horizontal"
                        height="2px"
                        backgroundColor="var(--orange-senne)"
                      />
                    </Flex>

                    <TableContainer
                      w="100%"
                      borderRadius="8px"
                      border="var(--cinza-card) 1px solid"
                      // height="380px"
                      maxH="250px"
                      overflowY="scroll"
                    >
                      <Table variant="simple">
                        <Thead backgroundColor="var(--cinza-bg)">
                          <Tr>
                            {/* <Th w="84px"></Th> */}
                            <Th borderLeft="var(--cinza-card) 1px solid">
                              Nome
                            </Th>

                            <Th
                              w="84px"
                              borderLeft="var(--cinza-card) 1px solid"
                            >
                              Ações
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody borderTop="1px solid var(--cinza-card)">
                          {listAdmin?.Users?.data?.map((item, index) => (
                            <Tr
                              key={index}
                              _hover={{
                                backgroundColor: "var(--orange-hover)",
                              }}
                            >
                              {/* <Td p="0 8px">
                            <Flex w="84px" justifyContent="center">
                            <Checkbox />
                          </Flex>
                          </Td> */}
                              <Td
                                p="0 8px"
                                borderLeft="1px solid var(--cinza-card)"
                              >
                                {item?.name}
                              </Td>

                              <Td
                                w="84px"
                                p="0 8px"
                                borderLeft="1px solid var(--cinza-card)"
                              >
                                <Menu>
                                  <MenuButton
                                    style={{
                                      backgroundColor: "transparent",
                                      display: "flex",
                                      margin: "0 auto",
                                    }}
                                    as={IconButton}
                                    icon={
                                      <GreenButton
                                        style={{
                                          padding: "0 8px 8px",
                                          width: "40px",
                                        }}
                                      >
                                        ...
                                      </GreenButton>
                                    }
                                  />
                                  <MenuList>
                                    <Link
                                      fontSize="12px"
                                      to={
                                        `/users/${item?.id}` ||
                                        navigate("/users")
                                      }
                                    >
                                      <MenuItem fontSize="12px">
                                        Editar cadastro
                                      </MenuItem>
                                    </Link>
                                  </MenuList>
                                </Menu>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                    <Flex mt="18px" justifyContent="end">
                      <Pagination />
                    </Flex>
                  </Box>
                  <Flex gap="13px" justifyContent="end">
                    <Box w="86px">
                      <CancelButton w="86px">Cancelar</CancelButton>
                    </Box>
                    <Box w="68px">
                      <PrimaryResultsButton type="submit">
                        Salvar
                      </PrimaryResultsButton>
                    </Box>
                  </Flex>
                </form>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      )}
    </>
  );
};
export default GroupProfile;

const CardImage = styled.div`
  display: flex;
  margin: 0 auto 16px;
  padding: 8px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 0px 24.7967px rgba(0, 0, 0, 0.1);
  img {
    width: 130px;
    height: 130px;
    border-radius: 60px;
    object-fit: cover;
  }
`;
