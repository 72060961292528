import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../images/logoHeaderReset.svg";

export default function HeaderLogo() {
  return (
    <Header>
      <Link to="/">
        <img src={Logo} alt="logo" />
      </Link>
    </Header>
  );
}

const Header = styled.header`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  /* text-align: center; */
  padding: 20px 0;
  background: white;

  @media (max-width: 414px) {
    padding: 10px;
  }
`;
