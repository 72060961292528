import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loading = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: "";
    width: 50px;
    height: 50px;
    border: 5px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: ${rotate} 1s linear infinite;
  }
`;

export default Loading;
