import TemplateRegister from "../../../components/TemplateRegister";

import { useNavigate } from "react-router-dom";

import { RegisterButton } from "../../../components/Buttons";
import { Flex, Text } from "@chakra-ui/react";

export default function CadastroSolicitadoLab() {
  const Navigate = useNavigate();
  return (
    <>
      <TemplateRegister>
        <Flex
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          h="calc(100vh - 103px)"
        >
          <Flex
            flexDirection="column"
            backgroundColor="white"
            p="32px 46px"
            borderRadius="8px"
          >
            <Flex
              textAlign="start"
              gap="16px"
              width="100%"
              flexDirection="column"
            >
              <Text
                fontSize="2.5rem"
                fontWeight="700"
                color="var(--orange-senne-secundary)"
                textAlign="center"
              >
                Solicitação de cadastro <br />
                realizada com sucesso
              </Text>
              <Text fontSize="0.8rem" w="540px">
                Sua <b>solicitação de cadastro </b>foi enviada para a Senne
                Liquor e está <b>pendente de ativação</b>, nossa equipe entrará
                em breve em contato.
              </Text>

              <RegisterButton
                background="var(--green-button)"
                onClick={() => Navigate("/login")}
              >
                Voltar para Login
              </RegisterButton>
            </Flex>
          </Flex>
        </Flex>
      </TemplateRegister>
    </>
  );
}
