import React, { useState } from "react";
import Loading from "../components/Loading";
import api from "../services/api";
import { toast } from "react-toastify";

export const ExamsContext = React.createContext({});

export const ExamsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  async function treatmentExams(uuid, numberTreatment, params) {
    const response = await api.get(
      `/treatment/exams/${uuid}/${numberTreatment}`,
      { params },
    );
    try {
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response?.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  }

  async function treatmentUnitExams(uuid, numberTreatment, r_id, seqexame) {
    try {
      const response = await api.get(
        `/treatment/exam/${uuid}/${numberTreatment}/${r_id}/${seqexame}`,
      );
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response?.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  }

  async function detailsExams(uuid, numberTreatment) {
    setIsLoading(true);

    try {
      const response = await api.get(
        `/treatment/details/${uuid}/${numberTreatment}`,
      );
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response?.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    } finally {
      setIsLoading(false);
    }
    
  }

  async function completeReport(uuid, numberTreatment, r_id) {
    try {
      const response = await api.get(
        `/treatment/report/${uuid}/${numberTreatment}/${r_id}`,
      );
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response?.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  }
  async function pacientProtocol(data) {
    try {
      const response = await api.post(`print/user/protocol`, data);
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  }

  async function createObservation(data) {
    try {
      const response = await api.post(`create/observation`, data);
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      console.log(error);
    }
  }

  async function showObservation(data) {
    const response = await api.get(`show/observation/${data}`);
    return response;
  }

  async function newAttendance(uuid, params) {
    setIsLoading(true);
    try {
      const response = await api.get(`/v2/list/attendance/${uuid}`, { params });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function newAttendanceRange(uuid, startDate, endDate, params) {
    setIsLoading(true);

    try {
      const response = await api.get(
        `/v2/list/attendance/range/${uuid}/${startDate}/${endDate}`,
        { params },
      );
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function listAppointment (uuid, type, params) {
    setIsLoading(true);
    try {
      const response = await api.get(
        `/v2/list/appointment/${uuid}/${type}`,
        { params },
      );
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    } finally {
      setIsLoading(false);
    }
  }  

  async function listAuthorization (uuid, type, params) {
    setIsLoading(true);
    try {
      const response = await api.get(
        `/v2/list/authorization/${uuid}/${type}`,
        { params },
      );
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  
  async function changePatientRelease (data) {
    setIsLoading(true);

    try {

      const response = await api.post(
        `/v2/patientrelease`, data
      );

      // console.log("Response Function",response.data);

      return response.data;
    } catch (error) {

      console.log("err",error);

      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    } finally {
      setIsLoading(false);
    }
  }    

  async function patientExamsDetails(uuid, numberTreatment, params) {
    setIsLoading(true);
    try {
      const response = await api.get(
        `/treatment/details/${uuid}/${numberTreatment}`,
        {
          params,
        }
      );
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response?.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function patientTreatmentExams(uuid, numberTreatment, params) {
    setIsLoading(true);
    try {
      const response = await api.get(
        `/treatment/exams/${uuid}/${numberTreatment}`,
        {
          params,
        },
      );
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response?.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function patientCompleteReport(uuid, numberTreatment, r_id) {
    setIsLoading(true);
    try {
      const response = await api.get(
        `/treatment/report/${uuid}/${numberTreatment}/${r_id}`,
      );
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response?.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <ExamsContext.Provider
      value={{
        completeReport,
        detailsExams,
        treatmentExams,
        pacientProtocol,
        treatmentUnitExams,
        createObservation,
        showObservation,
        newAttendance,
        newAttendanceRange,
        patientExamsDetails,
        patientTreatmentExams,
        patientCompleteReport,
        listAppointment,
        listAuthorization,
        changePatientRelease
      }}
    >
      {isLoading ? <Loading /> : null}
      {children}
    </ExamsContext.Provider>
  );
};
